import * as React from 'react';
import { history } from 'vms/app/configureApp';
import { AnchorButton, IButtonProps } from '@blueprintjs/core';

type Props = Omit<IButtonProps, 'onClick'> & { to: string };

const AppAnchorButton = (props: Props) => (
  <AnchorButton {...props} onClick={() => history.push(props.to)} />
);

export default AppAnchorButton;
