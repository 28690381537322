import * as _ from 'lodash-es';
import { createReducerNew } from '@kotify/rehelpers/reducerUtils';
import { PartDefinition, Part, PartVendor } from './types';
import * as Actions from './actions';

export interface State {
  entities: { [key: string]: Part };
  definitions: { [key: string]: PartDefinition };
  editDialogData: PartDefinition | null;
  isDefinitionsLoadedByTask: { [task: string]: boolean };
  vendors: {
    [id: string]: PartVendor;
  };
}

export const initialState: State = {
  entities: {},
  definitions: {},
  editDialogData: null,
  isDefinitionsLoadedByTask: {},
  vendors: {},
};

const loadPartDefinitionsSuccess = (
  state: State,
  { definitions }: { definitions: PartDefinition[] }
) => {
  state.definitions = {
    ...state.definitions,
    ..._.keyBy(definitions, 'id'),
    ..._.keyBy(_.flatMap(definitions, 'children'), 'id'),
  };
};

const openPartDefinitionEditDialog = (
  state: State,
  { definition }: { definition: PartDefinition }
) => {
  state.editDialogData = definition;
};

const definitionsLoadedForTask = (state: State, { task }: { task: string }) => {
  state.isDefinitionsLoadedByTask[task] = true;
};

const closePartDefinitionEditDialog = (state: State) => {
  state.editDialogData = null;
};

const loadPartsSuccess = (state: State, { parts }: { parts: Part[] }) => {
  state.entities = { ...state.entities, ..._.keyBy(parts, 'id') };
};

const savePartSuccess = (state: State, { part }: { part: Part }) => {
  state.entities[part.id] = part;
};

const updatePartVendorSuccess = (state: State, payload: { vendor: PartVendor }) => {
  state.vendors[payload.vendor.id] = payload.vendor;
};

const loadPartVendorsSuccess = (state: State, payload: { vendors: PartVendor[] }) => {
  state.vendors = { ...state.vendors, ..._.keyBy(payload.vendors, 'id') };
};

const deletePartSuccess = (state: State, payload: { part: Part }) => {
  delete state.entities[payload.part.id];
};

const partDefinitionDeleteSuccess = (
  state: State,
  { definition }: { definition: PartDefinition }
) => {
  const makeCustomParts = (d: PartDefinition) =>
    _.values(state.entities)
      .filter(part => part.definition && part.definition === d.id)
      .forEach(part => {
        state.entities[part.id].definition = null;
      });
  if (definition.children) {
    definition.children.forEach(child => {
      makeCustomParts(child);
      delete state.definitions[child.id];
    });
  }
  makeCustomParts(definition);
  delete state.definitions[definition.id];
};

export const reducer = createReducerNew<State>(initialState, {
  [Actions.LOAD_PART_DEFINITIONS_SUCCESS]: loadPartDefinitionsSuccess,
  [Actions.OPEN_PART_DEFINITIONS_EDIT_DIALOG]: openPartDefinitionEditDialog,
  [Actions.CLOSE_PART_DEFINITION_EDIT_DIALOG]: closePartDefinitionEditDialog,
  [Actions.PART_DEFINITIONS_LOADED_FOR_TASK]: definitionsLoadedForTask,
  [Actions.LOAD_PARTS_SUCCESS]: loadPartsSuccess,
  [Actions.LOAD_PART_VENDOR_SUCCESS]: loadPartVendorsSuccess,
  [Actions.UPDATE_PART_VENDOR_SUCCESS]: updatePartVendorSuccess,
  [Actions.PART_SAVE_SUCCESS]: savePartSuccess,
  [Actions.PART_DELETE_SUCCESS]: deletePartSuccess,
  [Actions.PART_DEFINITION_DELETE_SUCCESS]: partDefinitionDeleteSuccess,
});
