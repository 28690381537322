import * as _ from 'lodash-es';
import { createReducer } from '@kotify/rehelpers/reducerUtils';

import { NotificationSettingsEntry } from './types';
import { LOAD_NOTIFICATION_SETTINGS, NOTIFICATION_SETTINGS_UPDATED } from './actions';

export interface State {
  notificationSettings: {
    [kind: number]: NotificationSettingsEntry;
  };
}

export const initialState: State = {
  notificationSettings: {},
};

const loadNotificationSettings = (
  state: State,
  payload: { notificationSettings: NotificationSettingsEntry[] }
) => {
  state.notificationSettings = _.keyBy(payload.notificationSettings, 'kind');
};

const updateNotificationSetting = (
  state: State,
  payload: { notificationSettingsEntry: NotificationSettingsEntry }
) => {
  state.notificationSettings = {
    ...state.notificationSettings,
    [payload.notificationSettingsEntry.kind]: payload.notificationSettingsEntry,
  };
};

export const reducer = createReducer<State>(initialState, {
  [LOAD_NOTIFICATION_SETTINGS]: loadNotificationSettings,
  [NOTIFICATION_SETTINGS_UPDATED]: updateNotificationSetting,
});
