import { combineReducers } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  initialState as initialAccountsState,
  reducer as accountsReducer,
} from 'vms/features/accounts/reducer';
import {
  initialState as initialVehiclesState,
  reducer as vehiclesReducer,
} from 'vms/features/vehicles/reducer';
import {
  initialState as initialInspectionState,
  reducer as inspectionReducer,
} from 'vms/features/inspection/reducer';
import {
  initialState as initialFlagsState,
  reducer as flagsReducer,
} from 'vms/features/flags/reducer';
import {
  initialState as initialPartsState,
  reducer as partsReducer,
} from 'vms/features/parts/reducer';
import {
  initialState as initialLocationsState,
  reducer as locationsReducer,
} from 'vms/features/locations/reducer';
import {
  initialState as initialOrganizationState,
  reducer as organizationReducer,
} from 'vms/features/organizations/reducer';
import {
  initialState as initialNotesState,
  reducer as notesReducer,
} from 'vms/features/notes/reducer';
import {
  initialState as initialGPSState,
  reducer as gpsReducer,
} from 'vms/features/gps/reducer';
import {
  initialState as initialVendorSubletState,
  reducer as vendorSubletReducer,
} from 'vms/features/sublet/reducer';
import {
  initialState as initialDialogState,
  reducer as dialogReducer,
} from 'vms/features/dialog/reducer';
import {
  initialState as initialDeletedObjectsState,
  reducer as deletedObjectsReducer,
} from 'vms/features/deletedObjects/reducer';

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>;

export const initialState = {
  accounts: initialAccountsState,
  vehicles: initialVehiclesState,
  inspection: initialInspectionState,
  flags: initialFlagsState,
  parts: initialPartsState,
  locations: initialLocationsState,
  organization: initialOrganizationState,
  notes: initialNotesState,
  gps: initialGPSState,
  vendorSublets: initialVendorSubletState,
  dialog: initialDialogState,
  deletedObjects: initialDeletedObjectsState,
};

export type RootState = typeof initialState;

const rootReducer = combineReducers<RootState>({
  accounts: accountsReducer,
  vehicles: vehiclesReducer,
  inspection: inspectionReducer,
  flags: flagsReducer,
  parts: partsReducer,
  locations: locationsReducer,
  organization: organizationReducer,
  notes: notesReducer,
  gps: gpsReducer,
  vendorSublets: vendorSubletReducer,
  dialog: dialogReducer,
  deletedObjects: deletedObjectsReducer,
});

export default rootReducer;
