import axios, { AxiosResponse } from 'axios';
import { take, all, fork, put, call } from 'redux-saga/effects';
import * as actions from './actions';
import { RepairShop } from './types';

function* loadRepairShops(action: {
  type: typeof actions.LOAD_REPAIR_SHOPS;
  payload: PromisePayload<RepairShop[]>;
}) {
  const { resolve, reject } = action.payload;
  try {
    const response: AxiosResponse<PaginatedResults<RepairShop>> = yield axios.get<
      PaginatedResults<RepairShop>
    >('/repair-shops/', { params: { page_size: 1000 } });
    yield put(actions.loadRepairShopsSuccess(response.data.results));
    resolve(response.data.results);
  } catch (e) {
    reject(e);
  }
}

function* watchLoadRepairShops() {
  const a = yield take(actions.LOAD_REPAIR_SHOPS);
  yield call(loadRepairShops, a);
}

export default function* root() {
  yield all([fork(watchLoadRepairShops)]);
}
