import { RootState as State } from 'vms/app/rootReducer';
import { createSelector } from 'reselect';
import * as _ from 'lodash-es';

export const selectUsers = (state: State) => state.accounts.users;

export const selectCurrentUser = (state: State) =>
  state.accounts.currentUserId
    ? state.accounts.users[state.accounts.currentUserId]
    : null;

export const selectUserList = createSelector(
  selectUsers,
  users => _.values(users)
);
export const selectSortedUserList = createSelector(
  selectUsers,
  users => _.sortBy(_.values(users), 'name')
);
