import { DeletedObject } from './types';

export const LOAD_DELETED_OBJECTS_SUCCESS = 'LOAD_DELETED_OBJECTS_SUCCESS';
export const REMOVE_DELETED_OBJECT = 'REMOVE_DELETED_OBJECT';

export const loadDeletedObjectsSuccess = (objects: DeletedObject[]) => ({
  type: LOAD_DELETED_OBJECTS_SUCCESS,
  payload: { objects },
});

export const removeDeletedObject = (object: DeletedObject) => ({
  type: REMOVE_DELETED_OBJECT,
  payload: { object },
});
