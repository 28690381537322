import * as _ from 'lodash-es';
import { createReducer } from '@kotify/rehelpers/reducerUtils';

import { VendorSublet, RepairShop } from './types';
import {
  LOAD_REPAIR_SHOPS_SUCCESS,
  UPDATE_REPAIR_SHOP_SUCCESS,
  UPDATE_VENDOR_SUBLET_SUCCESS,
  LOAD_VENDOR_SUBLETS_SUCCESS,
} from './actions';

interface State {
  entities: {
    [id: string]: VendorSublet;
  };
  shops: {
    [id: string]: RepairShop;
  };
}

export const initialState: State = {
  entities: {},
  shops: {},
};

const updateRepairShopSuccess = (state: State, payload: { shop: RepairShop }) => {
  state.shops[payload.shop.id] = payload.shop;
};

const loadRepairShopsSuccess = (state: State, payload: { shops: RepairShop[] }) => {
  state.shops = { ...state.shops, ..._.keyBy(payload.shops, 'id') };
};

const loadVendorSubletsSuccess = (
  state: State,
  payload: { vendorSublets: VendorSublet[] }
) => {
  state.entities = { ...state.entities, ..._.keyBy(payload.vendorSublets, 'id') };
};

const updateVendorSubletSuccess = (
  state: State,
  payload: { vendorSublet: VendorSublet }
) => {
  state.entities = { ...state.entities, [payload.vendorSublet.id]: payload.vendorSublet };
};

export const reducer = createReducer<State>(initialState, {
  [LOAD_REPAIR_SHOPS_SUCCESS]: loadRepairShopsSuccess,
  [UPDATE_REPAIR_SHOP_SUCCESS]: updateRepairShopSuccess,
  [LOAD_VENDOR_SUBLETS_SUCCESS]: loadVendorSubletsSuccess,
  [UPDATE_VENDOR_SUBLET_SUCCESS]: updateVendorSubletSuccess,
});
