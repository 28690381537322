import * as _ from 'lodash-es';

import { createReducerNext } from '@kotify/rehelpers/reducerUtils';

import { Actions, LOAD_GPS_BRAND_SUCCESS, UPDATE_GPS_BRAND_SUCCESS } from './actions';
import { GPSBrand } from './types';

interface State {
  brands: { [id: string]: GPSBrand };
  isBrandsLoaded: boolean;
}

export const initialState: State = { brands: {}, isBrandsLoaded: false };

export const reducer = createReducerNext<State, Actions>(
  initialState,
  (state, action) => {
    switch (action.type) {
      case LOAD_GPS_BRAND_SUCCESS:
        state.brands = { ...state.brands, ..._.keyBy(action.payload, 'id') };
        state.isBrandsLoaded = true;
        return;
      case UPDATE_GPS_BRAND_SUCCESS:
        state.brands[action.payload.id] = action.payload;
        break;
      default:
    }
  }
);
