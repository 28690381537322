import * as React from 'react';
import * as _ from 'lodash-es';
import { connect } from 'react-redux';
import { RootState } from 'vms/app/rootReducer';
import { Classes, FormGroup, Icon, Callout, Intent } from '@blueprintjs/core';
import {
  InspectionTaskResult,
  InspectionCategory,
  InspectionTask,
} from 'vms/features/inspection/types';
import {
  selectInspectionCategoriesList,
  selectCachedAvailableActiveInspectionTasksListByCategory,
  selectCachedActiveAvailableInspectionResultsByTask,
} from 'vms/features/inspection/selectors';
import { loadInspectionTaskResults } from 'vms/features/inspection/actions';
import { isVehicleInUserWork } from 'vms/features/vehicles/selectors';
import { PartAddDialogEditableData } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { bindThunk } from 'vms/tsutils';
import { showAxiosError } from 'vms/app/AppToaster';

interface DispatchProps {
  loadInspectionTaskResults: (
    vehicle: string
  ) => ReturnType<ReturnType<typeof loadInspectionTaskResults>>;
}

interface StateProps {
  categories: InspectionCategory[];
  tasks: InspectionTask[];
  resultsByTask: { [taskId: string]: InspectionTaskResult };
  isInWork: boolean;
}

interface OwnProps {
  vehicleId: string;
  inspectionCategoryId: string | null;
  taskId: string | null;
  inspectionResultId: string | undefined;
  onChange: (update: Partial<PartAddDialogEditableData>) => void;
  taskErrors: {
    intent: string;
    helperText: JSX.Element[];
  };
}

type Props = StateProps & OwnProps & DispatchProps;

export class PartQuickRequestSection extends React.Component<Props> {
  componentDidMount() {
    this.props
      .loadInspectionTaskResults(this.props.vehicleId)
      .catch(error => showAxiosError(error));
  }

  onTaskChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const task = event.target.value;
    const { resultsByTask } = this.props;
    this.props.onChange({
      task,
      inspection_result: resultsByTask[task] ? resultsByTask[task].id : undefined,
      status: 'REQUESTED',
    });
  };

  onCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    this.props.onChange({
      inspectionCategoryId: event.target.value,
      task: null,
      inspection_result: undefined,
    });

  render() {
    return (
      <>
        <FormGroup label="Category">
          <div className={Classes.HTML_SELECT}>
            <select
              onChange={this.onCategoryChange}
              name="inspectionCategoryId"
              value={this.props.inspectionCategoryId || ''}
            >
              <option value="">---Select Category---</option>
              {this.props.categories.map(c => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
            <Icon icon="double-caret-vertical" />
          </div>
        </FormGroup>
        <FormGroup label="Task">
          <div className={Classes.HTML_SELECT}>
            <select
              onChange={this.onTaskChange}
              name="task"
              value={this.props.taskId || ''}
              disabled={!this.props.inspectionCategoryId}
            >
              <option value="">---Select Task---</option>
              {this.props.tasks.map(t => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
            <Icon icon="double-caret-vertical" />
          </div>
        </FormGroup>
        {Boolean(
          this.props.taskId && !this.props.inspectionResultId && !this.props.isInWork
        ) && (
          <Callout intent={Intent.WARNING}>
            You must start work on this vehicle in order to add part to this {'"Task"'}
          </Callout>
        )}
      </>
    );
  }
}

const mapState = (state: RootState, ownProps: OwnProps): StateProps => {
  const tasks = ownProps.inspectionCategoryId
    ? selectCachedAvailableActiveInspectionTasksListByCategory(
        state,
        ownProps.vehicleId,
        ownProps.inspectionCategoryId
      )
    : [];
  return {
    categories: selectInspectionCategoriesList(state),
    tasks: _.sortBy(tasks, 'name'),
    resultsByTask: selectCachedActiveAvailableInspectionResultsByTask(
      state,
      ownProps.vehicleId
    ),
    isInWork: isVehicleInUserWork(state, ownProps.vehicleId),
  };
};
const mapActions = (dispatch: ThunkDispatch<any, any, any>) => ({
  loadInspectionTaskResults: bindThunk(loadInspectionTaskResults, dispatch),
});
export default connect<StateProps, DispatchProps, OwnProps>(
  mapState,
  mapActions
)(PartQuickRequestSection);
