import '@babel/polyfill';
import './polyfills';
import 'react-hot-loader';
import * as React from 'react';

import { render } from 'react-dom';
import App from './app/App';

const root = document.getElementById('root');
render(<App />, root);
