import * as React from 'react';
import * as Sentry from '@sentry/browser';

interface State {
  error?: Error;
}

export default class ErrorBoundary extends React.Component<{}, State> {
  state: State = {};

  componentDidMount() {
    if (this.isReportingEnabled()) {
      Sentry.init({
        dsn:
          'https://558b3ebe8412b4c979712a5ac021c08d@o4506032078258176.ingest.sentry.io/4506070528884736',
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    if (this.isReportingEnabled()) {
      Sentry.withScope((scope: any) => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  isReportingEnabled() {
    return process.env.NODE_ENV === 'production';
  }

  handleShowDialog = () => {
    if (this.isReportingEnabled()) {
      Sentry.showReportDialog();
    }
  };

  render() {
    if (this.state.error) {
      return (
        <div className="snap p-5 text-center" onClick={this.handleShowDialog}>
          <p>
            We{"'"}re sorry — something{"'"}s gone wrong.
          </p>
          <p>
            Our team has been notified
            {this.isReportingEnabled() && ', but click here fill out a report'}.
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}
