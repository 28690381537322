import * as React from 'react';
import axios, { AxiosError } from 'axios';
import { connect } from 'react-redux';
import { InputGroup, ControlGroup, Button, Intent, H1 } from '@blueprintjs/core';
import { handleInputChange, InputChangeEvent } from '@kotify/rehelpers';
import { User } from './types';
import { handleLoginSuccess } from './actions';
import AppToaster from 'vms/app/AppToaster';
import * as styles from './Login.module.scss';

interface State {
  username: string;
  password: string;
  error: boolean;
}

interface Props {
  handleLoginSuccess: typeof handleLoginSuccess;
}

class Login extends React.Component<Props, State> {
  state: State = {
    username: '',
    password: '',
    error: false,
  };

  handleChange = (event: InputChangeEvent) => {
    this.setState(handleInputChange(event));
  };

  login = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { username, password } = this.state;
    axios.post<{ user: User }>('/login/', {}, { auth: { username, password } }).then(
      response => {
        this.setState({ error: false });
        this.props.handleLoginSuccess(response.data);
      },
      (error: AxiosError) => {
        this.setState({ error: true });
        if (error.response && error.response.status === 401) {
          AppToaster.show({ message: error.response.data.detail, intent: Intent.DANGER });
        } else {
          AppToaster.show({
            message: 'Network problem, please try again!',
            intent: Intent.DANGER,
          });
        }
      }
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.loginPanel}>
          <div>
            <H1>Magiloop VMS</H1>
          </div>
          <form onSubmit={this.login}>
            <ControlGroup fill vertical className={styles.loginGroup}>
              <InputGroup
                intent={this.state.error ? Intent.DANGER : Intent.NONE}
                required
                leftIcon="person"
                type="email"
                name="username"
                onChange={this.handleChange}
              />
              <InputGroup
                intent={this.state.error ? Intent.DANGER : Intent.NONE}
                required
                leftIcon="lock"
                type="password"
                name="password"
                onChange={this.handleChange}
              />
              <Button type="submit" text="Login" />
            </ControlGroup>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { handleLoginSuccess }
)(Login);
