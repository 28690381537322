import { Vehicle } from 'vms/features/vehicles/types';
import * as _ from 'lodash-es';

const KEY_NAME = 'recentVehicles';
const SIZE = 5;

export default class RecentVehicles {
  static saveToHistory(vehicle: Vehicle | null) {
    if (vehicle) {
      const vehicles = RecentVehicles.getHistory();
      const index = _.findIndex(vehicles, v => v.id === vehicle.id);
      if (index === 0) {
        return;
      } else if (index === -1) {
        vehicles.unshift(vehicle);
      } else {
        vehicles.splice(index, 1);
        vehicles.unshift(vehicle);
      }
      localStorage.setItem(KEY_NAME, JSON.stringify(vehicles.slice(0, SIZE)));
    }
  }

  static getHistory(): Vehicle[] {
    const data = localStorage.getItem(KEY_NAME) || '[]';
    return JSON.parse(data);
  }

  static clearHistory() {
    localStorage.removeItem(KEY_NAME);
  }
}
