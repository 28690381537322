import { History } from 'history';
import { requireAuthenticationFactory } from '@kotify/rehelpers/authUtils';
import { store } from 'vms/app/configureApp';
import { SAVE_LAST_LOCATION } from './actions';

const checkAuthenticated = (
  history: History,
  options: { redirect: string } = { redirect: '/login/' }
) => {
  const state = store.getState();
  if (!state.accounts.isAuthenticated) {
    store.dispatch({ type: SAVE_LAST_LOCATION, pathname: history.location.pathname });
    history.replace(options.redirect);
  }
};

const checkUnauthenticated = (
  history: History,
  options: { redirect: string } = { redirect: '/' }
) => {
  const state = store.getState();
  if (state.accounts.isAuthenticated) {
    history.replace(options.redirect);
  }
};

export const requireAuthenticated = requireAuthenticationFactory(checkAuthenticated);
export const requireUnauthenticated = requireAuthenticationFactory(checkUnauthenticated);
