import * as React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router';
import { Spinner } from '@blueprintjs/core';
import { history } from './configureApp';
import Base from './layout/Base';
import Loadable, { LoadingComponentProps } from 'react-loadable';
import NotFound from './layout/NotFound';
import {
  requireAuthenticated,
  requireUnauthenticated,
} from 'vms/features/accounts/utils';
import Login from 'vms/features/accounts/Login';
import { redirectIfInWork } from 'vms/features/accounts/wrappers';

const Loader = (props: LoadingComponentProps) => {
  if (props.error) {
    return <div>Error!</div>;
  } else if (props.timedOut) {
    return <div>Taking a long time...</div>;
  } else if (props.pastDelay) {
    return <Spinner />;
  } else {
    return null;
  }
};

const VehicleRouter = Loadable({
  loader: () => import('vms/features/vehicles/router' /* webpackChunkName: "vehicles" */),
  loading: Loader,
});

const PartRouter = Loadable({
  loader: () => import('vms/features/parts/router' /* webpackChunkName: "parts" */),
  loading: Loader,
});

const SettingsRouter = Loadable({
  loader: () => import('vms/features/settings/router' /* webpackChunkName: "settings" */),
  loading: Loader,
});

const ChartRouter = Loadable({
  loader: () => import('vms/features/charts/router' /* webpackChunkName: "charts" */),
  loading: Loader,
});

const AuthRoutes = () => (
  <Base>
    <Switch>
      <Route
        path="/"
        exact
        strict
        render={() => <Redirect push={false} to="/vehicles/?ordering=in_status_age" />}
      />
      <Route exact={false} path="/vehicles" component={VehicleRouter as any} />
      <Route exact={false} path="/parts" component={PartRouter as any} />
      <Route exact={false} path="/settings" component={SettingsRouter as any} />
      <Route exact={false} path="/charts" component={ChartRouter as any} />
      <Route component={NotFound} />
    </Switch>
  </Base>
);

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      // redirect to the same url with trailing slash appended
      <Route
        path="/:url*"
        exact
        strict
        render={props => <Redirect push={false} to={`${props.location.pathname}/`} />}
      />
      <Route exact={true} path="/login/" component={requireUnauthenticated(Login)} />
      <Route
        exact={false}
        path="/"
        component={requireAuthenticated(redirectIfInWork(AuthRoutes))}
      />
    </Switch>
  </Router>
);

export default AppRouter;
