import axios from 'axios';
import { InspectionTaskResult } from './types';

export const saveInspectionTaskResult = (data: Partial<InspectionTaskResult>) => {
  if (data.id) {
    return axios.put<InspectionTaskResult>(`/inspection-results/${data.id}/`, data);
  } else {
    return axios.post<InspectionTaskResult>('/inspection-results/', data);
  }
};
