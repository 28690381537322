import thunk, { ThunkMiddleware } from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../rootSaga';

import rootReducer, { RootState } from '../rootReducer';

const configureStore = (initialState: RootState) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = applyMiddleware(thunk as ThunkMiddleware<RootState>, sagaMiddleware);
  const store = createStore(rootReducer, initialState, middleware);
  sagaMiddleware.run(rootSaga);
  return store;
};

export type AppStore = ReturnType<typeof configureStore>;
export default configureStore;
