import { createAction, ActionsUnion } from '@kotify/rehelpers/reduxUtils';
import { DialogState, EditableData, DialogCode } from './types';

export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';
export const DIALOG_UPDATE_DATA = 'DIALOG_UPDATE_DATA';
export const DIALOG_SET_ERRORS = 'DIALOG_SET_ERRORS';
export const REQUEST_START_DIALOG = 'REQUEST_START_DIALOG';
export const REQUEST_CANCEL_DIALOG = 'REQUEST_CANCEL_DIALOG';
export const REQUEST_FINISH_DIALOG = 'REQUEST_FINISH_DIALOG';

// eslint-disable-next-line import/export
export const Actions = {
  startDialog: <A extends { dialogCode: DialogCode }>(data: A) =>
    createAction(REQUEST_START_DIALOG, data),
  openDialog: (data: Partial<DialogState>) => createAction(DIALOG_OPEN, data),
  closeDialog: () => createAction(DIALOG_CLOSE),
  cancelDialog: () => createAction(REQUEST_CANCEL_DIALOG),
  finishDialog: () => createAction(REQUEST_FINISH_DIALOG),
  changeDialogData: (editableData: Partial<EditableData>) =>
    createAction(DIALOG_UPDATE_DATA, editableData),
  setErrors: (errors: any) => createAction(DIALOG_SET_ERRORS, errors),
};

// eslint-disable-next-line import/export
export type Actions = ActionsUnion<typeof Actions>;
