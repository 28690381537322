import * as React from 'react';
import { connect } from 'react-redux';
import {
  Intent,
  Navbar,
  NavbarGroup,
  Alignment,
  NavbarHeading,
  NavbarDivider,
  Button,
  Popover,
  Menu,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from 'vms/app/rootReducer';
import AnchorButton from 'vms/common/components/AnchorButton';
import { selectCurrentUser } from 'vms/features/accounts/selectors';
import { selectVehicleInWork } from 'vms/features/vehicles/selectors';
import { User } from 'vms/features/accounts/types';
import { InWork } from 'vms/features/vehicles/types';
import { logout } from 'vms/features/accounts/actions';
import RecentVehicles from 'vms/features/vehicles/RecentVehicles';

interface Props {
  user: User | null;
  vehicleInWork: InWork | null;
  logout: () => any;
}

const initialState = {
  isProfileOpen: false,
  logoutInProgress: false,
  isWatchHistoryOpen: false,
};

export class Nav extends React.Component<
  Props & RouteComponentProps<{}>,
  typeof initialState
> {
  state = initialState;

  handleProfileInteraction = (nextOpenState: boolean) => {
    this.setState({ isProfileOpen: nextOpenState });
  };

  handleWatchHistoryInteraction = (nextOpenState: boolean) => {
    this.setState({ isWatchHistoryOpen: nextOpenState });
  };

  logout = () => {
    this.setState({ logoutInProgress: true });
    this.props.logout();
  };

  render() {
    const { user, vehicleInWork } = this.props;
    if (!user) {
      return null;
    }
    const recentVehicles = RecentVehicles.getHistory();
    return (
      <Navbar>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>Magiloop VMS</NavbarHeading>
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          {vehicleInWork && (
            <Button
              icon="wrench"
              intent={Intent.WARNING}
              onClick={() =>
                this.props.history.push(`/vehicles/${vehicleInWork.vehicle}/`)
              }
            />
          )}
          <NavbarDivider />
          <AnchorButton minimal icon="home" text="Home" to="/" />
          <Popover
            isOpen={this.state.isWatchHistoryOpen}
            onInteraction={state => this.handleWatchHistoryInteraction(state)}
            canEscapeKeyClose={true}
            interactionKind={PopoverInteractionKind.CLICK}
          >
            <Button minimal icon="history">
              Recent Vehicles
            </Button>
            <Menu>
              {recentVehicles.length ? (
                recentVehicles.map(v => (
                  <Menu.Item
                    key={v.id}
                    text={`${v.make} ${v.model}`}
                    onClick={() => this.props.history.push(`/vehicles/${v.id}/`)}
                  />
                ))
              ) : (
                <Menu.Item text="Empty" disabled={true} />
              )}
            </Menu>
          </Popover>
          <Popover
            isOpen={this.state.isProfileOpen}
            onInteraction={state => this.handleProfileInteraction(state)}
            canEscapeKeyClose={true}
            interactionKind={PopoverInteractionKind.CLICK}
          >
            <Button minimal icon="user">
              {user.name}
            </Button>
            <Menu>
              <Menu.Item
                disabled={this.state.logoutInProgress}
                icon="log-out"
                text="Logout"
                onClick={this.logout}
              />
            </Menu>
          </Popover>
        </NavbarGroup>
      </Navbar>
    );
  }
}

const mapState = (state: RootState) => ({
  user: selectCurrentUser(state),
  vehicleInWork: selectVehicleInWork(state),
});

export default withRouter(
  connect(
    mapState,
    { logout }
  )(Nav)
);
