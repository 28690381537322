import * as _ from 'lodash-es';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { RootState as State } from 'vms/app/rootReducer';
import { selectCurrentUser } from 'vms/features/accounts/selectors';
import { isInitialInspection } from './utils';

export const selectVehicles = (state: State) => state.vehicles.entities;
export const selectVehicleInWork = (state: State) => state.vehicles.vehicleInWork;
export const selectVehicleCounts = (state: State) => state.vehicles.meta.counts;
export const selectVehicleBuyers = (state: State) => state.vehicles.meta.buyers;
export const selectVehicleSources = (state: State) => state.vehicles.meta.sources;

export const selectByStockNumbers = createSelector(
  selectVehicles,
  vehicles => _.keyBy(_.values(vehicles), 'stock_number')
);

export const selectIsVehicleInInitialInspectionStatus = (
  state: State,
  vehicleId: string
) => isInitialInspection(state.vehicles.entities[vehicleId].status);

export const selectVehicle = createCachedSelector(
  selectVehicles,
  (_state: State, vehicleId: string) => vehicleId,
  (vehicles, vehicleId) => vehicles[vehicleId]
)((_vehicles, vehicleId) => vehicleId);

export const isVehicleInUserWork = createCachedSelector(
  selectCurrentUser,
  selectVehicle,
  (user, vehicle) => Boolean(vehicle.in_work && user && user.id === vehicle.in_work.user)
)((_state, vehicleId) => vehicleId);
