import * as React from 'react';
import { Vendor } from '../types';
import { Classes, Icon } from '@blueprintjs/core';

export interface Props {
  selectedVendorId: string | null;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
  disabled?: boolean;
  vendors: Vendor[];
  loadVendors: () => any;
  name?: string;
}

export default class VendorSelect extends React.Component<Props> {
  componentDidMount() {
    if (this.props.vendors.length === 0) {
      this.props.loadVendors();
    }
  }

  render() {
    const { handleChange, selectedVendorId, vendors, disabled, name } = this.props;
    return (
      <div className={Classes.HTML_SELECT}>
        <select
          onChange={handleChange}
          value={selectedVendorId || ''}
          name={name || 'vendor'}
          disabled={disabled}
        >
          <option value="">---</option>
          {vendors.map(v => (
            <option key={v.id} value={v.id}>
              {v.name}
            </option>
          ))}
        </select>
        <Icon icon="double-caret-vertical" />
      </div>
    );
  }
}
