import * as React from 'react';
import { Intent } from '@blueprintjs/core';
import * as _ from 'lodash-es';

export default class FormHelper<D> {
  static getFieldErrorsList(validationError?: ValidationError): string[] {
    if (validationError === undefined) {
      return [];
    }
    if (validationError instanceof Array) {
      return validationError;
    }
    return _.flatMap(validationError);
  }

  /**
   * Shift list error when removing tag from tag input.
   * @param error Field error.
   */
  static shiftListError = (error?: ListValidationError) => (
    deletedIndex: number
  ): ListValidationError | undefined => {
    if (!error) {
      return error;
    }
    const newError: ListValidationError = {};
    Object.keys(error)
      .map(index => parseInt(index, 10))
      .filter(index => index !== deletedIndex)
      .forEach(index => {
        if (index < deletedIndex) {
          newError[index] = error[index];
        } else {
          newError[index - 1] = error[index];
        }
      });
    return newError;
  };

  errors: ValidationErrors<D>;

  constructor(errors: ValidationErrors<D>) {
    this.errors = errors;
  }

  /**
   * Convert Django's ArrayField validation error into DANGER color for tag.
   * @param field Field name
   */
  convertErrorForTag(field: keyof D) {
    return (index: number) => {
      const fieldErrors = this.errors[field];
      if (fieldErrors && Array.isArray(fieldErrors[index])) {
        return { intent: Intent.DANGER };
      }
      return {};
    };
  }

  getIntent(field: keyof D | 'non_field_errors') {
    return this.errors[field] ? Intent.DANGER : Intent.NONE;
  }

  getErrors(field: keyof D | 'non_field_errors') {
    const fieldErrors = FormHelper.getFieldErrorsList(this.errors[field]);
    return {
      intent: this.getIntent(field),
      helperText: fieldErrors.map((e, i) => <div key={`${i}-${e}`}> {e} </div>),
    };
  }
}
