import * as _ from 'lodash-es';
import { createReducer } from '@kotify/rehelpers/reducerUtils';

import { Note, NoteCreatorData } from './types';
import {
  CREATE_NOTE_SUCCESS,
  LOAD_NOTES_SUCCESS,
  OPEN_NOTE_CREATOR,
  CLOSE_NOTE_CREATOR,
} from './actions';

interface State {
  entities: { [id: string]: Note };
  noteCreatorData: NoteCreatorData | null;
}

export const initialState: State = {
  entities: {},
  noteCreatorData: null,
};

const createNoteSuccess = (state: State, payload: { note: Note }) => {
  state.entities[payload.note.id] = payload.note;
};

const loadNotesSuccess = (state: State, payload: { notes: Note[] }) => {
  state.entities = { ...state.entities, ..._.keyBy(payload.notes, 'id') };
};

const openNoteCreator = (state: State, payload: { data: NoteCreatorData }) => {
  state.noteCreatorData = payload.data;
};

const closeNoteCreator = (state: State) => {
  state.noteCreatorData = null;
};

export const reducer = createReducer<State>(initialState, {
  [CREATE_NOTE_SUCCESS]: createNoteSuccess,
  [LOAD_NOTES_SUCCESS]: loadNotesSuccess,
  [OPEN_NOTE_CREATOR]: openNoteCreator,
  [CLOSE_NOTE_CREATOR]: closeNoteCreator,
});
