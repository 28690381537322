import { RootState as State } from 'vms/app/rootReducer';
import * as _ from 'lodash-es';
import { createSelector } from 'reselect';

export const selectLocations = (state: State) => state.locations.entities;
export const selectLocationsList = createSelector(
  selectLocations,
  locations => _.values(locations)
);
export const selectLocationsListSorted = createSelector(
  selectLocationsList,
  locations => _.sortBy(locations, 'name')
);
