import { createReducer } from '@kotify/rehelpers/reducerUtils';
import { Location } from './types';
import * as _ from 'lodash-es';
import { LOAD_LOCATIONS_SUCCESS } from './actions';

export interface State {
  entities: { [id: string]: Location };
}

export const initialState: State = {
  entities: {},
};

const loadLocationsSuccess = (state: State, { locations }: { locations: Location[] }) => {
  state.entities = { ...state.entities, ..._.keyBy(locations, 'id') };
};

export const reducer = createReducer<State>(initialState, {
  [LOAD_LOCATIONS_SUCCESS]: loadLocationsSuccess,
});
