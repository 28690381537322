import * as React from 'react';
import { connect } from 'react-redux';
import { Classes, Dialog, Intent, Button, FormGroup, TextArea } from '@blueprintjs/core';
import axios, { AxiosError } from 'axios';
import FormHelper from 'vms/common/components/FormHelper';
import { Note, NoteCreatorData, NoteModel } from './types';
import { createNoteSuccess, closeNoteCreator } from './actions';
import { vehicleInStatusCreated } from 'vms/features/vehicles/actions';
import { RootState } from 'vms/app/rootReducer';
import { selectNoteCreatorData } from './selectors';

interface Props {
  noteCreatorData: NoteCreatorData | null;
  createNoteSuccess: typeof createNoteSuccess;
  vehicleInStatusCreated: typeof vehicleInStatusCreated;
  closeNoteCreator: typeof closeNoteCreator;
}

interface State {
  text: string;
  errors: ValidationErrors<Note>;
  saveInProgress: boolean;
}

const initialState: State = {
  text: '',
  errors: {},
  saveInProgress: false,
};

class NoteCreator extends React.Component<Props, State> {
  state = initialState;

  onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ text: event.target.value });
  };

  save = () => {
    const { noteCreatorData } = this.props;
    if (!noteCreatorData) {
      return;
    }
    this.setState({ saveInProgress: true });
    axios
      .post<Note>('/notes/', {
        text: this.state.text,
        ...noteCreatorData.params,
      })
      .then(
        response => {
          this.props.createNoteSuccess(response.data);
          if (response.data.model === NoteModel.VehicleInStatus) {
            // Note created on VehicleInStatus model. But instead VehicleInStatus.id we send Vehicle.id and get VehicleInStatus.id in response, because VehicleInStatus may not exist.
            const requestId = noteCreatorData.params.object_id;
            const vehicleId: string =
              typeof requestId === 'string' ? requestId : requestId[0];
            this.props.vehicleInStatusCreated(response.data.object_id, vehicleId);
          }
          this.close();
          if (noteCreatorData.afterNoteAdd) {
            noteCreatorData.afterNoteAdd();
          }
        },
        (error: AxiosError) => {
          this.setState({
            saveInProgress: false,
            errors:
              error.response && error.response.status === 400 ? error.response.data : {},
          });
        }
      );
  };

  close = () => {
    this.setState(initialState);
    this.props.closeNoteCreator();
  };

  render() {
    const formHelper = new FormHelper(this.state.errors);
    return (
      <Dialog
        icon="inbox"
        isOpen={Boolean(this.props.noteCreatorData)}
        onClose={this.close}
        title="Add note"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup {...formHelper.getErrors('text')}>
            <TextArea
              name="text"
              rows={5}
              fill={true}
              intent={formHelper.getIntent('text')}
              onChange={this.onTextChange}
              value={this.state.text}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Cancel"
              onClick={this.close}
              disabled={this.state.saveInProgress}
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={this.save}
              text="Add"
              disabled={this.state.saveInProgress}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapState = (state: RootState) => ({
  noteCreatorData: selectNoteCreatorData(state),
});

export default connect(
  mapState,
  { createNoteSuccess, closeNoteCreator, vehicleInStatusCreated }
)(NoteCreator);
