import { RootState } from 'vms/app/rootReducer';
import { AnyAction } from 'redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

export function bindThunk<R>(
  actionCreator: (...args: any[]) => ThunkAction<R, RootState, undefined, AnyAction>,
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
) {
  return (...args: any[]) => dispatch(actionCreator(...args));
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
