import * as React from 'react';
import { connect } from 'react-redux';
import axios, { AxiosError } from 'axios';
import { Spinner, NonIdealState, Button } from '@blueprintjs/core';
import {
  vehiclesMetaLoadSuccess,
  loadVehicleInWorkSuccess,
} from 'vms/features/vehicles/actions';
import { loadLocationsSuccess } from 'vms/features/locations/actions';
import { loadUsersSuccess } from 'vms/features/accounts/actions';
import {
  loadInspectionCategoriesSuccess,
  loadInspectionTasksSuccess,
} from 'vms/features/inspection/actions';
import { Actions as FlagActions } from 'vms/features/flags/actions';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import NoteCreator from 'vms/features/notes/NoteCreator';
import AppDialog from 'vms/features/dialog/AppDialog';

interface Props {
  vehiclesMetaLoadSuccess: typeof vehiclesMetaLoadSuccess;
  loadUsersSuccess: typeof loadUsersSuccess;
  loadLocationsSuccess: typeof loadLocationsSuccess;
  loadInspectionCategoriesSuccess: typeof loadInspectionCategoriesSuccess;
  loadInspectionTasksSuccess: typeof loadInspectionTasksSuccess;
  loadVehicleInWorkSuccess: typeof loadVehicleInWorkSuccess;
  loadFlagsSuccess: typeof FlagActions.loadFlagsSuccess;
}

interface State {
  error: string;
  isOrgDataReceived: boolean;
}

class Base extends React.Component<Props, State> {
  state: State = {
    error: '',
    isOrgDataReceived: false,
  };

  fetchOrgData = () => {
    axios.get('/organization/').then(
      response => {
        this.props.vehiclesMetaLoadSuccess(response.data.vehicles_meta);
        this.props.loadUsersSuccess(response.data.users);
        this.props.loadLocationsSuccess(response.data.locations);
        this.props.loadInspectionCategoriesSuccess(response.data.inspection_categories);
        this.props.loadInspectionTasksSuccess(response.data.inspection_tasks);
        this.props.loadVehicleInWorkSuccess(response.data.vehicle_in_work);
        this.props.loadFlagsSuccess(response.data.flags);
        this.setState({ isOrgDataReceived: true });
      },
      (error: AxiosError) => {
        this.setState({ error: error.message });
      }
    );
  };

  componentDidMount() {
    this.fetchOrgData();
  }

  render() {
    if (!this.state.isOrgDataReceived) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          {this.state.error ? (
            <NonIdealState
              icon="error"
              title="Error"
              description={this.state.error}
              action={
                <Button
                  text="Try again"
                  icon="refresh"
                  onClick={() => {
                    this.setState({ error: '' });
                    this.fetchOrgData();
                  }}
                />
              }
            />
          ) : (
            <Spinner size={100} />
          )}
        </div>
      );
    }
    return (
      <>
        <Navbar />
        <div style={{ display: 'flex' }}>
          <Sidebar />
          <main>{this.props.children}</main>
        </div>
        <AppDialog />
        <NoteCreator />
        <footer />
      </>
    );
  }
}

export default connect(
  null,
  {
    vehiclesMetaLoadSuccess,
    loadUsersSuccess,
    loadLocationsSuccess,
    loadInspectionCategoriesSuccess,
    loadInspectionTasksSuccess,
    loadVehicleInWorkSuccess,
    loadFlagsSuccess: FlagActions.loadFlagsSuccess,
  }
)(Base);
