import * as React from 'react';
import {
  Button,
  FormGroup,
  InputGroup,
  TextArea,
  Card,
  Elevation,
  Intent,
  Classes,
  HTMLTable,
} from '@blueprintjs/core';
import {
  Part,
  PartEditDialogEditableData,
  PartEditDialogReadOnlyData,
  PartDefinition,
} from 'vms/features/parts/types';
import PartVendorSelect from 'vms/features/parts/PartVendorSelect';
import FormHelper from 'vms/common/components/FormHelper';
import PartStatusSelect from 'vms/features/parts/PartStatusSelect';
import { formatPartFullName } from 'vms/features/parts/services';
import { DateInput } from '@blueprintjs/datetime';
import { formatDate, reformatDTZ } from 'vms/common/utils';
import { handleInputChange } from '@kotify/rehelpers';
import { DialogProps } from 'vms/features/dialog/types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { selectUsers } from 'vms/features/accounts/selectors';
import { selectLocations } from 'vms/features/locations/selectors';
import { RootState } from 'vms/app/rootReducer';
import { User } from 'vms/features/accounts/types';
import { Location } from 'vms/features/locations/types';
import { selectPartDefinitions } from 'vms/features/parts/selectors';
import { deletePart } from 'vms/features/parts/actions';
import { showAxiosError } from 'vms/app/AppToaster';
import { bindThunk } from 'vms/tsutils';

interface StateProps {
  definitions: { [id: string]: PartDefinition };
  users: { [id: string]: User };
  locations: { [id: string]: Location };
}

interface DispatchProps {
  deletePart: (part: Part) => ReturnType<ReturnType<typeof deletePart>>;
}

type Props = DialogProps<
  PartEditDialogEditableData,
  PartEditDialogReadOnlyData,
  ValidationErrors<Part>
> &
  StateProps &
  DispatchProps;

const MAX_DATE = new Date(new Date().getFullYear() + 1, 12, 31);

class PartEditDialog extends React.Component<Props> {
  handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const update = handleInputChange(event);
    this.props.handleChange(update);
    this.props.setErrors({});
  };

  handleChangeETA = (date: Date) => {
    this.props.handleChange({ eta: date });
    this.props.setErrors({});
  };

  getNames = (): string => {
    const part = this.props.editableData;
    const { definitions } = this.props;
    if (part) {
      return formatPartFullName(part, definitions);
    }
    return '';
  };

  delete = () => {
    this.props
      .deletePart(this.props.editableData)
      .then(this.props.onCancel, showAxiosError);
  };

  render() {
    const { isInProgress } = this.props;
    const { image } = this.props.readOnlyData;
    const { users, locations } = this.props;
    const data = this.props.editableData;
    const formHelper = new FormHelper<Part>(this.props.errors);
    return (
      <>
        <div className={Classes.DIALOG_BODY}>
          {image && (
            <Card
              elevation={Elevation.TWO}
              style={{ width: '100%', marginBottom: '20px' }}
            >
              <img src={image} alt="GPS Image" width="100%" height="auto" />
            </Card>
          )}

          <HTMLTable className="disable-form-group-margin">
            <tbody>
              <tr>
                <td style={{ minWidth: '200px' }}>VIN</td>
                <td>{data.vehicle.vin}</td>
              </tr>

              <tr>
                <td>Make</td>
                <td>{data.vehicle.make}</td>
              </tr>

              <tr>
                <td>Model</td>
                <td>{data.vehicle.model}</td>
              </tr>

              <tr>
                <td>Technician</td>
                <td>{users[data.created_by].name}</td>
              </tr>

              <tr>
                <td>Location</td>
                <td>{data.location && locations[data.location].name}</td>
              </tr>

              <tr>
                <td>Part Name</td>
                <td>{this.getNames()}</td>
              </tr>
              {data.ordered_by && data.ordered_at && (
                <tr>
                  <td>Ordered</td>
                  <td>
                    By {users[data.ordered_by].name} at {reformatDTZ(data.ordered_at)}
                  </td>
                </tr>
              )}
              {data.received_by && data.received_at && (
                <tr>
                  <td>Received</td>
                  <td>
                    By {users[data.received_by].name} at {reformatDTZ(data.received_at)}
                  </td>
                </tr>
              )}

              <tr>
                <td>Part#</td>
                <td>
                  <FormGroup {...formHelper.getErrors('number')} inline={true}>
                    <InputGroup
                      name="number"
                      value={data.number}
                      onChange={this.handleChange}
                      intent={formHelper.getIntent('number')}
                      maxLength={50}
                    />
                  </FormGroup>
                </td>
              </tr>

              <tr>
                <td>Status</td>
                <td>
                  <FormGroup {...formHelper.getErrors('status')} inline={true}>
                    <PartStatusSelect
                      status={data.status}
                      handleChange={this.handleChange}
                    />
                  </FormGroup>
                </td>
              </tr>

              <tr>
                <td>Vendor</td>
                <td>
                  <FormGroup {...formHelper.getErrors('vendor')} inline={true}>
                    <PartVendorSelect
                      selectedVendorId={data.vendor}
                      handleChange={this.handleChange}
                    />
                  </FormGroup>
                </td>
              </tr>

              <tr>
                <td>ETA</td>
                <td>
                  <FormGroup {...formHelper.getErrors('eta')} inline={true}>
                    <DateInput
                      maxDate={MAX_DATE}
                      formatDate={(date: Date) => formatDate(date, true)}
                      onChange={this.handleChangeETA}
                      parseDate={str => new Date(str)}
                      placeholder={'MM/DD/YYYY'}
                      value={data.eta}
                    />
                  </FormGroup>
                </td>
              </tr>

              <tr>
                <td>Price</td>
                <td>
                  <FormGroup {...formHelper.getErrors('price')} inline={true}>
                    <InputGroup
                      name="price"
                      value={data.price || ''}
                      onChange={this.handleChange}
                      intent={formHelper.getIntent('price')}
                    />
                  </FormGroup>
                </td>
              </tr>

              <tr>
                <td>Note</td>
                <td>
                  <FormGroup {...formHelper.getErrors('notes')} inline={true}>
                    <TextArea
                      name="notes"
                      value={data.notes}
                      onChange={this.handleChange}
                      intent={formHelper.getIntent('notes')}
                      rows={4}
                    />
                  </FormGroup>
                </td>
              </tr>
            </tbody>
          </HTMLTable>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div
            className={Classes.DIALOG_FOOTER_ACTIONS}
            style={{ justifyContent: 'space-between' }}
          >
            <Button
              text="Delete"
              loading={isInProgress}
              onClick={this.delete}
              intent={Intent.DANGER}
            />
            <Button text="Save" loading={isInProgress} onClick={this.props.onOk} />
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: RootState) => ({
  users: selectUsers(state),
  locations: selectLocations(state),
  definitions: selectPartDefinitions(state),
});

const mapActions = (dispatch: ThunkDispatch<any, any, any>) => ({
  deletePart: bindThunk(deletePart, dispatch),
});

export default connect(
  mapState,
  mapActions
)(PartEditDialog);
