import { createReducerNext } from '@kotify/rehelpers/reducerUtils';

import {
  Actions,
  DIALOG_OPEN,
  DIALOG_CLOSE,
  DIALOG_UPDATE_DATA,
  DIALOG_SET_ERRORS,
} from './actions';
import { DialogState } from './types';

export const initialState: DialogState = {
  code: null,
  editableData: null,
  readOnlyData: null,
  errors: {},
  readyToRender: false,
  isInProgress: false,
};

export const reducer = createReducerNext<DialogState, Actions>(
  initialState,
  (state: DialogState, action) => {
    switch (action.type) {
      case DIALOG_OPEN:
        return { ...state, ...action.payload };
      case DIALOG_CLOSE:
        return initialState;
      case DIALOG_UPDATE_DATA:
        state.editableData = {
          ...state.editableData,
          ...action.payload,
        } as DialogState['editableData'];
        return state;
      case DIALOG_SET_ERRORS:
        return { ...state, errors: action.payload };
      default:
        return state;
    }
  }
);
