import { Dispatch } from 'redux';
import axios from 'axios';
import {
  PartDefinition,
  Part,
  PartVendor,
  PartAddDialogInitialData,
  PartEditDialogInitialData,
} from './types';
import { Actions as DialogActions } from 'vms/features/dialog/actions';
import * as api from './api';

export const LOAD_PART_DEFINITIONS_SUCCESS = 'LOAD_PART_DEFINITIONS_SUCCESS';
export const LOAD_PARTS_SUCCESS = 'LOAD_PARTS_SUCCESS';
export const PART_DEFINITIONS_LOADED_FOR_TASK = 'PART_DEFINITIONS_LOADED_FOR_TASK';
export const OPEN_PART_DEFINITIONS_EDIT_DIALOG = 'OPEN_PART_DEFINITIONS_EDIT_DIALOG';
export const CLOSE_PART_DEFINITION_EDIT_DIALOG = 'CLOSE_PART_DEFINITION_EDIT_DIALOG';
export const CLOSE_PART_ADD_DIALOG = 'CLOSE_PART_ADD_DIALOG';
export const CLOSE_PART_EDIT_DIALOG = 'CLOSE_PART_EDIT_DIALOG';
export const LOAD_PART_VENDORS = 'LOAD_PART_VENDORS';
export const LOAD_PART_VENDOR_SUCCESS = 'LOAD_PART_VENDOR_SUCCESS';
export const UPDATE_PART_VENDOR_SUCCESS = 'UPDATE_PART_VENDOR_SUCCESS';
export const PART_SAVE = 'PART_SAVE';
export const PART_SAVE_SUCCESS = 'PART_SAVE_SUCCESS';
export const PART_DELETE_SUCCESS = 'PART_DELETE_SUCCESS';
export const PART_DEFINITION_DELETE_SUCCESS = 'PART_DEFINITION_DELETE_SUCCESS';

export const loadPartDefinitionsSuccess = (definitions: PartDefinition[]) => ({
  type: LOAD_PART_DEFINITIONS_SUCCESS,
  payload: { definitions },
});

export const closePartDefinitionEditDialog = () => ({
  type: CLOSE_PART_DEFINITION_EDIT_DIALOG,
});

export const definitionsLoadedForTask = (task: string) => ({
  type: PART_DEFINITIONS_LOADED_FOR_TASK,
  payload: { task },
});

export const loadPartsSuccess = (parts: Part[]) => ({
  type: LOAD_PARTS_SUCCESS,
  payload: { parts },
});

export const savePartSuccess = (part: Part) => ({
  type: PART_SAVE_SUCCESS,
  payload: { part },
});

export const updatePartVendorSuccess = (vendor: PartVendor) => ({
  type: UPDATE_PART_VENDOR_SUCCESS,
  payload: { vendor },
});

export const loadPartVendorsSuccess = (vendors: PartVendor[]) => ({
  type: LOAD_PART_VENDOR_SUCCESS,
  payload: { vendors },
});

export const openPartDefinitionEditDialog = (definition: PartDefinition | null) => {
  let d: PartDefinition;
  if (definition === null) {
    d = {
      id: '',
      parent: null,
      inspection_task: '',
      name: '',
      is_notes_required: false,
      note_prompt: '',
      children: [],
      default_vendor: null,
    };
  } else {
    d = definition;
  }
  return {
    type: OPEN_PART_DEFINITIONS_EDIT_DIALOG,
    payload: {
      definition: d,
    },
  };
};

export const closePartAddDialog = () => ({ type: CLOSE_PART_ADD_DIALOG });
export const closePartEditDialog = () => ({ type: CLOSE_PART_EDIT_DIALOG });

export const partDefinitionDeleteSuccess = (definition: PartDefinition) => ({
  type: PART_DEFINITION_DELETE_SUCCESS,
  payload: { definition },
});

export const savePart = (data: Partial<Part>) => (dispatch: Dispatch<any>) => {
  return new Promise<Part>((resolve, reject) => {
    dispatch({ type: PART_SAVE, payload: { part: data, resolve, reject } });
  });
};

export const partAdd = (
  vehicleId: string,
  inspectionTaskId?: string,
  inspectionResultId?: string,
  part?: Part
) =>
  DialogActions.startDialog<PartAddDialogInitialData>({
    dialogCode: 'PART_ADD_DIALOG',
    inspectionTaskId,
    vehicleId,
    inspectionResultId,
    part,
  });

export const partEdit = (part: Part) =>
  DialogActions.startDialog<PartEditDialogInitialData>({
    dialogCode: 'PART_EDIT_DIALOG',
    part,
  });

export const deletePart = (part: Part) => (dispatch: Dispatch<any>) =>
  api.deletePart(part.id).then(() => {
    dispatch({ type: PART_DELETE_SUCCESS, payload: { part } });
  });

export const loadPartVendors = () => (dispatch: Dispatch<any>) => {
  return new Promise<PartVendor[]>((resolve, reject) => {
    dispatch({
      type: LOAD_PART_VENDORS,
      payload: { resolve, reject },
    });
  });
};

export const loadPartDefinitions = () => (dispatch: Dispatch<any>) => {
  return axios
    .get<PaginatedResults<PartDefinition>>('/part-definitions/')
    .then(response => {
      dispatch(loadPartDefinitionsSuccess(response.data.results));
    });
};
