import * as _ from 'lodash-es';
import { createReducerNew } from '@kotify/rehelpers/reducerUtils';
import { DeletedObject } from './types';
import * as Actions from './actions';

export interface State {
  objects: { [key: string]: DeletedObject | undefined };
}

export const initialState: State = {
  objects: {},
};

const loadObjectsSuccess = (state: State, payload: { objects: DeletedObject[] }) => {
  state.objects = { ...state.objects, ..._.keyBy(payload.objects, 'id') };
};

const removeObject = (state: State, payload: { object: DeletedObject }) => {
  state.objects[payload.object.id] = undefined;
};

export const reducer = createReducerNew<State>(initialState, {
  [Actions.LOAD_DELETED_OBJECTS_SUCCESS]: loadObjectsSuccess,
  [Actions.REMOVE_DELETED_OBJECT]: removeObject,
});
