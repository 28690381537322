import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'vms/app/rootReducer';
import { User } from 'vms/features/accounts/types';
import { selectCurrentUser } from 'vms/features/accounts/selectors';
import { selectVehicleInWork } from 'vms/features/vehicles/selectors';
import { InWork } from 'vms/features/vehicles/types';
import { canStartWorkForOther } from './rules';

interface Props extends RouteComponentProps<{}> {
  user: User | null;
  inWork: null | InWork;
}

export function redirectIfInWork(Wrapped: React.ComponentType<{}>) {
  class Inner extends React.Component<Props> {
    componentDidMount() {
      this.checkRoute();
    }

    componentDidUpdate() {
      this.checkRoute();
    }

    checkRoute = () => {
      const { user, inWork, history } = this.props;
      if (!canStartWorkForOther(user) && inWork) {
        const url = `/vehicles/${inWork.vehicle}/`;
        if (history.location.pathname !== url) {
          history.replace(url);
        }
      }
    };

    render() {
      const { user, inWork, ...props } = this.props;
      return <Wrapped {...props} />;
    }
  }

  const mapState = (state: RootState) => ({
    user: selectCurrentUser(state),
    inWork: selectVehicleInWork(state),
  });

  return withRouter(connect(mapState)(Inner));
}

export function hideIfInWork(Wrapped: React.ComponentType<{}>) {
  // tslint:disable:max-classes-per-file
  class Inner extends React.Component<Props> {
    render() {
      const { user, inWork, ...props } = this.props;
      if (!canStartWorkForOther(user) && inWork) {
        return null;
      }
      return <Wrapped {...props} />;
    }
  }

  const mapState = (state: RootState) => ({
    user: selectCurrentUser(state),
    inWork: selectVehicleInWork(state),
  });

  return withRouter(connect(mapState)(Inner));
}
