import { InspectionStage } from './types';
import { STATUS_CODES } from 'vms/features/vehicles/constants';

export const STAGES: { [code: string]: InspectionStage[] | undefined } = {
  [STATUS_CODES.PRE_INSPECTION]: ['inspection_status'],
  [STATUS_CODES.TECHNICIAN_INSPECTION]: ['inspection_status'],
  [STATUS_CODES.TECHNICIAN_BUILD]: ['technician_status'],
  [STATUS_CODES.QC]: ['qc_status'],
  [STATUS_CODES.LOT_REPAIR]: ['inspection_status', 'technician_status'],
};

export const MCMC_TIRES_CATEGORY_ID = '7612e9ec-d35b-4753-b9d3-2d38877ca02f';
