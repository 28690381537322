import * as _ from 'lodash-es';
import { createSelector } from 'reselect';

import { RootState as State } from 'vms/app/rootReducer';
import { GPSInfo } from './types';

export const selectGPSBrands = (state: State) => state.gps.brands;
export const selectGPSBrandsList = createSelector(
  selectGPSBrands,
  brands => _.values(brands)
);
export const selectIsGPSBrandsLoaded = (state: State) => state.gps.isBrandsLoaded;
export const selectGPSBrandName = (state: State, gpsInfo: GPSInfo | null) =>
  gpsInfo &&
  gpsInfo.brand &&
  state.gps.brands[gpsInfo.brand] &&
  state.gps.brands[gpsInfo.brand].name;
