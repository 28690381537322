import axios from 'axios';
import { Note, NoteQueryParams, NoteCreatorData } from './types';
import { ThunkResult } from 'vms/app/rootReducer';
import * as _ from 'lodash-es';

export const LOAD_NOTES_SUCCESS = 'LOAD_NOTES_SUCCESS';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const OPEN_NOTE_CREATOR = 'OPEN_NOTE_CREATOR';
export const CLOSE_NOTE_CREATOR = 'CLOSE_NOTE_CREATOR';

export const createNoteSuccess = (note: Note) => ({
  type: CREATE_NOTE_SUCCESS,
  note,
});

export const loadNotesSuccess = (notes: Note[]) => ({
  type: LOAD_NOTES_SUCCESS,
  notes,
});

export const openNoteCreator = (data: NoteCreatorData) => ({
  type: OPEN_NOTE_CREATOR,
  data,
});

export const closeNoteCreator = () => ({
  type: CLOSE_NOTE_CREATOR,
});

export const loadNotes = (
  params: NoteQueryParams
): ThunkResult<Promise<Note[]>> => dispatch => {
  if (_.isEmpty(params.object_id)) {
    return Promise.resolve([]);
  }
  return axios
    .get<PaginatedResults<Note>>('/notes/', {
      params: { ...params, page_size: 1000 },
    })
    .then(response => {
      dispatch(loadNotesSuccess(response.data.results));
      return response.data.results;
    });
};
