import axios, { AxiosResponse } from 'axios';
import { take, all, fork, put, call, select } from 'redux-saga/effects';
import { Actions, LOAD_GPS_BRANDS } from './actions';
import { GPSBrand } from './types';
import { selectIsGPSBrandsLoaded } from 'vms/features/gps/selectors';

function* loadGPSBrands(action: ReturnType<typeof Actions.loadGPSBrands>) {
  const promise = action.meta && action.meta.promise;
  const isBrandsLoades = yield select(selectIsGPSBrandsLoaded);
  if (isBrandsLoades) {
    return;
  }
  try {
    const response: AxiosResponse<PaginatedResults<GPSBrand>> = yield axios.get<
      PaginatedResults<GPSBrand>
    >('/gps-brands/', { params: { page_size: 1000 } });
    yield put(Actions.loadGPSBrandsSuccess(response.data.results));
    if (promise) {
      promise.resolve(response.data.results);
    }
  } catch (e) {
    if (promise) {
      promise.reject(e);
    }
  }
}

function* watchLoadGpsBrands() {
  const a = yield take(LOAD_GPS_BRANDS);
  yield call(loadGPSBrands, a);
}

export default function* root() {
  yield all([fork(watchLoadGpsBrands)]);
}
