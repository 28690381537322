import { fork, all, put, actionChannel, take, call, flush } from 'redux-saga/effects';
import { Channel } from 'redux-saga';
import {
  Actions,
  REQUEST_START_DIALOG,
  REQUEST_CANCEL_DIALOG,
  REQUEST_FINISH_DIALOG,
} from './actions';
import dialogsConfig from './dialogsConfig';
import { showAxiosError } from 'vms/app/AppToaster';

export function* watchOpenDialogRequest(chan: Channel<typeof REQUEST_START_DIALOG>) {
  // tslint:disable-next-line:no-constant-condition
  while (true) {
    const { payload } = yield take(chan);
    const code = payload.dialogCode;
    yield put(Actions.openDialog({ readyToRender: false, code }));
    const dialog = dialogsConfig[payload.dialogCode];
    if (!dialog) {
      throw Error(`Unknown dialog code ${payload.dialogCode}`);
    }
    const data = yield call(dialog.loadData, payload);
    yield put(Actions.openDialog({ ...data, readyToRender: true, code }));
    while (true) {
      const action = yield take([REQUEST_CANCEL_DIALOG, REQUEST_FINISH_DIALOG]);
      if (action.type === REQUEST_CANCEL_DIALOG) {
        yield put(Actions.closeDialog());
        yield flush(chan);
        break;
      } else {
        try {
          yield call(dialog.saveData, action.payload);
        } catch (e) {
          if (e.code === 'BAD_REQUEST_ERROR') {
            yield put(Actions.setErrors(e.data));
            showAxiosError(e.originalError);
          } else {
            // eslint-disable-next-line no-console
            console.error(e);
          }
          continue;
        }
        yield put(Actions.closeDialog());
        break;
      }
    }
  }
}

export default function* root() {
  const chan = yield actionChannel(REQUEST_START_DIALOG);
  yield all([fork(watchOpenDialogRequest, chan)]);
}
