import { Flag, VehicleFlag } from './types';
import { Vehicle } from 'vms/features/vehicles/types';
import { createAction, ActionsUnion } from '@kotify/rehelpers/reduxUtils';

export const LOAD_FLAGS_SUCCESS = 'LOAD_FLAGS_SUCCESS';
export const FLAG_ADD = 'FLAG_ADD';
export const FLAG_REMOVE = 'FLAG_REMOVE';
export const FLAG_CLOSE_DIALOGS = 'FLAG_CLOSE_DIALOGS';

// eslint-disable-next-line import/export
export const Actions = {
  loadFlagsSuccess: (flags: Flag[]) => createAction(LOAD_FLAGS_SUCCESS, { flags }),
  flagAdd: (flag: Flag, vehicle: Vehicle) => createAction(FLAG_ADD, { flag, vehicle }),
  flagRemove: (vehicleFlag: VehicleFlag, vehicle: Vehicle) =>
    createAction(FLAG_REMOVE, { vehicleFlag, vehicle }),
  flagCloseDialogs: () => createAction(FLAG_CLOSE_DIALOGS),
};

// eslint-disable-next-line import/export
export type Actions = ActionsUnion<typeof Actions>;
