import * as React from 'react';
import { Classes, Icon } from '@blueprintjs/core';
import { STATUS_CHOICES } from 'vms/features/parts/constants';

interface Props {
  status: string;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => any;
  children?: React.ReactNode;
}

const PartStatusSelect = (props: Props) => (
  <div className={Classes.HTML_SELECT}>
    <select onChange={props.handleChange} value={props.status} name="status">
      {props.children}
      {Object.keys(STATUS_CHOICES).map(code => (
        <option key={code} value={code}>
          {STATUS_CHOICES[code]}
        </option>
      ))}
    </select>
    <Icon icon="double-caret-vertical" />
  </div>
);

export default PartStatusSelect;
