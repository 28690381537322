import * as qs from 'qs';
import axios, { AxiosTransformer, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Intent } from '@blueprintjs/core';
import { User } from './types';
import { history } from 'vms/app/configureApp';
import AppToaster from 'vms/app/AppToaster';
import RecentVehicles from 'vms/features/vehicles/RecentVehicles';
import { isoFormat, transformDateFields } from 'vms/common/utils';
import { RootState } from 'vms/app/rootReducer';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const UNAUTHORIZED_RESPONSE = 'UNAUTHORIZED_RESPONSE';
export const SAVE_LAST_LOCATION = 'SAVE_LAST_LOCATION';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';

export const handleLoginSuccess = ({ user }: { user: User }) => (
  dispatch: Dispatch<any>
) => {
  localStorage.setItem('user', JSON.stringify(user));
  dispatch({
    type: LOGIN_SUCCESS,
    user,
  });
  history.replace('/');
};

export const handleUnauthorized = () => {
  localStorage.removeItem('user');
  RecentVehicles.clearHistory();
  window.location.href = '/login/';
};

const initUser = () => (dispatch: Dispatch<any>) => {
  const userStr = localStorage.getItem('user');
  if (userStr) {
    dispatch({
      type: LOGIN_SUCCESS,
      user: JSON.parse(userStr),
    });
  }
};

export const logout = () => () =>
  axios.post('/logout/').then(handleUnauthorized, () =>
    AppToaster.show({
      message: 'Network problem, please try again!',
      intent: Intent.DANGER,
    })
  );

const buildArray = <T>(d: T | undefined) => {
  if (d) {
    if (Array.isArray(d)) {
      return d;
    }
    return [d];
  }
  return [];
};

export const initAuthSystem = () => (dispatch: ThunkDispatch<RootState, {}, any>) => {
  dispatch(initUser());
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    'X-Requested-With': 'XMLHttpRequest',
  };
  const transformResponse: AxiosTransformer = data => {
    transformDateFields(['eta'], data);
    return data;
  };
  const transformRequest: AxiosTransformer = data => {
    if (data && data.eta) {
      data.eta = isoFormat(data.eta, true);
    }
    return data;
  };
  axios.defaults.transformResponse = [
    ...buildArray(axios.defaults.transformResponse),
    transformResponse,
  ];
  axios.defaults.transformRequest = [
    transformRequest,
    ...buildArray(axios.defaults.transformRequest),
  ];
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  axios.defaults.baseURL = '/api/v1/';
  axios.defaults.paramsSerializer = params =>
    qs.stringify(params, { arrayFormat: 'repeat' });
  axios.interceptors.response.use(
    response => {
      return response;
    },
    (error: AxiosError) => {
      if (
        error.response &&
        error.response.status === 401 &&
        history.location.pathname !== '/login/'
      ) {
        handleUnauthorized();
        // eslint-disable-next-line no-console
        console.log('auth failure');
      }
      if (error.response && error.response.status === 400) {
        // temporary for debug
        // eslint-disable-next-line no-console
        console.log(`ERROR: ${JSON.stringify(error.response.data)}`);
      }
      return Promise.reject(error);
    }
  );
};

export const loadUsersSuccess = (users: User[]) => ({ type: LOAD_USERS_SUCCESS, users });
