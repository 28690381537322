import * as _ from 'lodash-es';
import { PartDefinition, Part } from 'vms/features/parts/types';
import { STATUS_CODES } from 'vms/features/vehicles/constants';

export const formatPartFullName = (
  part: Part,
  partDefinitions: { [id: string]: PartDefinition }
): string => {
  const names: string[] = [part.name];
  let parentId = part.definition;
  let definition: PartDefinition | undefined;
  while (parentId) {
    definition = partDefinitions[parentId];
    if (!definition) {
      break;
    }
    parentId = definition.parent;
  }
  // Save only root part name.
  if (definition && definition.name !== part.name) {
    names.push(definition.name);
  }

  let statusText = 'A';
  switch (part.vehicle_status) {
    case STATUS_CODES.PRE_INSPECTION:
    case STATUS_CODES.TECHNICIAN_INSPECTION:
      statusText = 'I';
      break;
    case STATUS_CODES.TECHNICIAN_BUILD:
      statusText = 'B';
      break;
    case STATUS_CODES.LOT_REPAIR:
      statusText = 'L';
      break;
    default:
      break;
  }
  return names.join(', ') + ` (${statusText})`;
};

export function sortParts<P extends object>(
  parts: P[],
  keyGetter: (p: P) => string | number
): P[] {
  return _.reverse(_.sortBy(parts, keyGetter));
}
