import * as _history from 'history';

import configureStore from './store/configureStore';
import { initialState } from './rootReducer';
import { initAuthSystem } from 'vms/features/accounts/actions';

const history = _history.createBrowserHistory();
const store = configureStore(initialState, history);

store.dispatch(initAuthSystem());

export { store, history };
