import * as React from 'react';
import {
  Classes,
  FormGroup,
  InputGroup,
  TextArea,
  Card,
  Elevation,
  H5,
  Icon,
  Checkbox,
} from '@blueprintjs/core';
import {
  PartDefinition,
  PartAddDialogEditableData,
  Part,
  PartAddDialogReadOnlyData,
} from './types';
import * as _ from 'lodash-es';
import FormHelper from 'vms/common/components/FormHelper';
import { MCMC_TIRES_CATEGORY_ID } from 'vms/features/inspection/constants';
import { handleInputChange } from '@kotify/rehelpers';
import PartQuickRequestSection from './PartQuickRequestSection';

interface Props {
  definitions: { [key: string]: PartDefinition };
  image: string | null;

  editableData: PartAddDialogEditableData;
  readOnlyData: PartAddDialogReadOnlyData;
  errors: ValidationErrors<Part>;
  onChange: (update: Partial<PartAddDialogEditableData>) => void;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default class PartAddDialogBody extends React.Component<Props> {
  onParentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value) {
      const def = this.props.definitions[event.target.value];
      this.props.onChange({
        definition: null,
        isCustom: false,
        parentDefinition: def.id,
      });
    } else {
      this.props.onChange({ parentDefinition: null, definition: null, isCustom: true });
    }
  };

  onChildChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange({ definition: event.target.value });
  };

  onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => this.props.onChange(handleInputChange(event));

  getNotesParams = () => {
    const { parentDefinition, definition } = this.props.editableData;
    const { definitions } = this.props;
    if (this.props.editableData.isCustom) {
      return { required: false, placeholder: '' };
    }
    if (
      (parentDefinition && definition === parentDefinition) ||
      (parentDefinition && !definition)
    ) {
      const defs = definitions;
      const id = parentDefinition;
      return { required: defs[id].is_notes_required, placeholder: defs[id].note_prompt };
    }
    if (definition && parentDefinition) {
      const defs = _.keyBy(definitions[parentDefinition].children, 'id');
      const id = definition;
      return { required: defs[id].is_notes_required, placeholder: defs[id].note_prompt };
    }
    return { required: false, placeholder: '' };
  };

  render() {
    const {
      parentDefinition,
      definition,
      inspectionCategoryId,
      isCustom,
    } = this.props.editableData;
    const { definitions } = this.props;
    const formHelper = new FormHelper<Part & { task: string | null }>(this.props.errors);
    const notesParams = this.getNotesParams();
    const children = parentDefinition ? definitions[parentDefinition].children || [] : [];
    return (
      <div className={Classes.DIALOG_BODY}>
        {this.props.readOnlyData.isQuickRequest && (
          <PartQuickRequestSection
            taskId={this.props.editableData.task}
            vehicleId={this.props.readOnlyData.vehicleId}
            inspectionCategoryId={this.props.editableData.inspectionCategoryId}
            inspectionResultId={this.props.editableData.inspection_result}
            onChange={this.props.onChange}
            taskErrors={formHelper.getErrors('task')}
          />
        )}
        <FormGroup label="Part" {...formHelper.getErrors('definition')}>
          <div className={Classes.HTML_SELECT}>
            <select
              onChange={this.onParentChange}
              name="parent_part"
              value={parentDefinition || ''}
            >
              <option value="">Custom Part</option>
              {_.sortBy(_.values(definitions).filter(d => d.children), 'name').map(c => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
            <Icon icon="double-caret-vertical" />
          </div>
        </FormGroup>
        {!isCustom && Boolean(children.length) && (
          <FormGroup label="Child Part">
            <div className={Classes.HTML_SELECT}>
              <select
                name="child_part"
                onChange={this.onChildChange}
                value={definition || ''}
              >
                <option value="">---</option>
                {children.map(p => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </select>
              <Icon icon="double-caret-vertical" />
            </div>
          </FormGroup>
        )}
        {isCustom && (
          <FormGroup
            label="Name"
            labelInfo="(required)"
            {...formHelper.getErrors('name')}
          >
            <InputGroup
              value={this.props.editableData.name}
              name="name"
              onChange={this.onChange}
              intent={formHelper.getIntent('name')}
            />
          </FormGroup>
        )}
        {(isCustom || definition || parentDefinition) && (
          <FormGroup
            label="Notes"
            labelInfo={notesParams.required ? '(required)' : ''}
            {...formHelper.getErrors('notes')}
          >
            <TextArea
              placeholder={notesParams.placeholder}
              intent={formHelper.getIntent('notes')}
              cols={50}
              rows={5}
              value={this.props.editableData.notes}
              name="notes"
              onChange={this.onChange}
            />
          </FormGroup>
        )}
        <FormGroup label="Image" {...formHelper.getErrors('image')}>
          <input
            type="file"
            onChange={this.props.onFileChange}
            name="image"
            placeholder="Select image..."
          />
        </FormGroup>
        {inspectionCategoryId === MCMC_TIRES_CATEGORY_ID && !this.props.readOnlyData.id && (
          <FormGroup>
            <Checkbox
              checked={this.props.editableData.createForAllTasks}
              name="createForAllTasks"
              onChange={event =>
                this.onChange(event as React.ChangeEvent<HTMLInputElement>)
              }
            >
              Order for all wheels
            </Checkbox>
          </FormGroup>
        )}
        {this.props.image && (
          <Card interactive={true} elevation={Elevation.TWO}>
            <H5>Saved Image:</H5>
            <img src={this.props.image} alt="GPS Image" width="100%" height="auto" />
          </Card>
        )}
      </div>
    );
  }
}
