import { Position, Toaster, Intent } from '@blueprintjs/core';
import { AxiosError } from 'axios';

const AppToaster = Toaster.create({
  className: 'recipe-toaster',
  position: Position.TOP_RIGHT,
});

export const showGlobalError = (
  message = 'Error happened, please refresh the page and try again!'
) => AppToaster.show({ message, intent: Intent.DANGER });

export const showError = (message: string | JSX.Element) => {
  AppToaster.show({ message, intent: Intent.DANGER });
};

export const showAxiosError = (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 400 && error.response.data.non_field_errors) {
      error.response.data.non_field_errors.forEach((m: string) => showError(m));
    } else if (error.response.status === 400) {
      showError('Validation error happend! Please check the input.');
    } else {
      showGlobalError();
    }
  } else {
    showGlobalError();
  }
};

export default AppToaster;
