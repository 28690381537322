import { RootState as State } from 'vms/app/rootReducer';
import { createSelector } from 'reselect';
import * as _ from 'lodash-es';
import { PartAddDialogReadOnlyData, PartAddDialogEditableData } from './types';

export const selectParts = (state: State) => state.parts.entities;
export const selectEditDialogData = (state: State) => state.parts.editDialogData;
export const selectPartDefinitions = (state: State) => state.parts.definitions;
export const selectIsPartDefinitionsLoadedByTask = (state: State) =>
  state.parts.isDefinitionsLoadedByTask;
export const selectPartVendors = (state: State) => state.parts.vendors;

export const selectPartVendorsList = createSelector(
  selectPartVendors,
  vendors => _.values(vendors)
);

export const selectPartDefinitionsByTask = createSelector(
  selectPartDefinitions,
  defs => _.mapValues(_.groupBy(_.values(defs), 'inspection_task'), v => _.keyBy(v, 'id'))
);

export const selectPartAddDialogSaveData = (state: State) => {
  const readOnlyData = state.dialog.readOnlyData as PartAddDialogReadOnlyData;
  const editableData = state.dialog.editableData as PartAddDialogEditableData;
  return {
    editableData,
    readOnlyData,
  };
};
