import axios from 'axios';
import { RepairShop, VendorSublet } from './types';
import { Dispatch } from 'redux';

export const LOAD_REPAIR_SHOPS = 'LOAD_REPAIR_SHOPS';
export const LOAD_REPAIR_SHOPS_SUCCESS = 'LOAD_REPAIR_SHOPS_SUCCESS';
export const UPDATE_REPAIR_SHOP_SUCCESS = 'UPDATE_REPAIR_SHOP_SUCCESS';
export const LOAD_VENDOR_SUBLETS_SUCCESS = 'LOAD_VENDOR_SUBLETS_SUCCESS';
export const UPDATE_VENDOR_SUBLET_SUCCESS = 'UPDATE_VENDOR_SUBLETS_SUCCESS';

export const updateRepairShopSuccess = (shop: RepairShop) => ({
  type: UPDATE_REPAIR_SHOP_SUCCESS,
  shop,
});

export const loadRepairShopsSuccess = (shops: RepairShop[]) => ({
  type: LOAD_REPAIR_SHOPS_SUCCESS,
  shops,
});

export const loadVendorSubletsSuccess = (vendorSublets: VendorSublet[]) => ({
  type: LOAD_VENDOR_SUBLETS_SUCCESS,
  vendorSublets,
});

export const updateVendorSubletSuccess = (vendorSublet: VendorSublet) => ({
  type: UPDATE_VENDOR_SUBLET_SUCCESS,
  vendorSublet,
});

export const loadRepairShops = () => (dispatch: Dispatch<any>) => {
  return new Promise<RepairShop[]>((resolve, reject) => {
    dispatch({
      type: LOAD_REPAIR_SHOPS,
      payload: { resolve, reject },
    });
  });
};

export const loadVendorSublets = (vehicleId: string) => (dispatch: Dispatch<any>) => {
  return axios
    .get<PaginatedResults<VendorSublet>>('/vendor-sublets/', {
      params: { vehicle: vehicleId, page_size: 1000 },
    })
    .then(response => {
      dispatch(loadVendorSubletsSuccess(response.data.results));
    });
};
