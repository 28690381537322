import axios, { AxiosRequestConfig } from 'axios';
import { Part } from './types';
import { BadRequestError } from 'vms/common/utils';

export const fetchPartDefinitions = (config?: AxiosRequestConfig) =>
  axios.get('/part-definitions/', config);

export const savePart = (data: Partial<Part> | FormData) => {
  let id: string | File | null | undefined;
  if (data instanceof FormData) {
    id = data.get('id');
  } else {
    id = data.id;
  }
  let response;
  if (id) {
    response = axios.put<Part>(`/parts/${id}/`, data);
  } else {
    response = axios.post<Part>('/parts/', data);
  }
  return response.catch(err => {
    if (err.response && err.response.status === 400) {
      throw new BadRequestError(err.response.data, err);
    }
    throw err;
  });
};

export const deletePart = (partId: string) => axios.delete(`/parts/${partId}/`);
