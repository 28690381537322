import { Dispatch } from 'redux';

import { createAction, ActionsUnion } from '@kotify/rehelpers/reduxUtils';

import { GPSBrand } from './types';

export const LOAD_GPS_BRANDS = 'LOAD_GPS_BRANDS';
export const LOAD_GPS_BRAND_SUCCESS = 'LOAD_GPS_BRAND_SUCCESS';
export const UPDATE_GPS_BRAND_SUCCESS = 'UPDATE_GPS_BRAND_SUCCESS';

// eslint-disable-next-line import/export
export const Actions = {
  updateGPSBrandSuccess: (brand: GPSBrand) =>
    createAction(UPDATE_GPS_BRAND_SUCCESS, brand),
  loadGPSBrands: (meta?: ActionMeta<GPSBrand[]>) =>
    createAction(LOAD_GPS_BRANDS, undefined, meta),
  loadGPSBrandsSuccess: (brands: GPSBrand[]) =>
    createAction(LOAD_GPS_BRAND_SUCCESS, brands),
};

// eslint-disable-next-line import/export
export type Actions = ActionsUnion<typeof Actions>;

export const loadGPSBrandsPromise = (dispatch: Dispatch<Actions>) => {
  return new Promise<GPSBrand[]>((resolve, reject) => {
    dispatch(Actions.loadGPSBrands({ promise: { resolve, reject } }));
  });
};
