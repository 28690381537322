import axios from 'axios';
import {
  InspectionCategory,
  InspectionTask,
  InspectionTaskResult,
  InspectionTaskNote,
} from './types';
import { Part } from 'vms/features/parts/types';
import { ThunkResult } from 'vms/app/rootReducer';

export const LOAD_INSPECTION_CATEGORIES_SUCCESS = 'LOAD_INSPECTION_CATEGORIES_SUCCESS';
export const LOAD_INSPECTION_TASKS_SUCCESS = 'LOAD_INSPECTION_TASKS_SUCCESS';
export const LOAD_INSPECTION_RESULT_SUCCESS = 'LOAD_INSPECTION_RESULT_SUCCESS';
export const UPDATE_INSPECTION_RESULT = 'UPDATE_INSPECTION_RESULT';
export const INSPECTION_TASK_NOTE_CREATED = 'INSPECTION_TASK_NOTE_CREATED';
export const INSPECTION_RESULT_ADD_PART = 'INSPECTION_RESULT_ADD_PART';
export const ADD_INSPECTION_TASK_SUCCESS = 'ADD_INSPECTION_TASK_SUCCESS';

export const loadInspectionCategoriesSuccess = (categories: InspectionCategory[]) => ({
  type: LOAD_INSPECTION_CATEGORIES_SUCCESS,
  payload: { categories },
});
export const loadInspectionTasksSuccess = (tasks: InspectionTask[]) => ({
  type: LOAD_INSPECTION_TASKS_SUCCESS,
  payload: { tasks },
});
export const loadInspectionResultsSuccess = (results: InspectionTaskResult[]) => ({
  type: LOAD_INSPECTION_RESULT_SUCCESS,
  payload: { results },
});
export const updateInspectionResults = (result: InspectionTaskResult) => ({
  type: UPDATE_INSPECTION_RESULT,
  payload: { result },
});
export const inspectionTaskNoteCreated = (note: InspectionTaskNote) => ({
  type: INSPECTION_TASK_NOTE_CREATED,
  payload: { note },
});
export const inspectionResultAddPart = (inspectionResultId: string, part: Part) => ({
  type: INSPECTION_RESULT_ADD_PART,
  payload: { part, inspectionResultId },
});
export const addInspectionTaskSuccess = (task: InspectionTask) => ({
  type: ADD_INSPECTION_TASK_SUCCESS,
  payload: { task },
});

export const loadInspectionTaskResults = (
  vehicleId: string
): ThunkResult<Promise<any>> => dispatch => {
  return axios
    .get('/inspection-results/', { params: { vehicle: vehicleId, page_size: 1000 } })
    .then(response => {
      dispatch(loadInspectionResultsSuccess(response.data.results));
    });
};

export const saveInspectionCategory = (
  data: Partial<InspectionCategory>
): ThunkResult<Promise<InspectionCategory>> => dispatch => {
  let request;
  if (data.id) {
    request = axios.put<InspectionCategory>(`/inspection-categories/${data.id}/`, data);
  } else {
    request = axios.post<InspectionCategory>('/inspection-categories/', data);
  }
  return request.then(response => {
    dispatch(loadInspectionCategoriesSuccess([response.data]));
    return response.data;
  });
};

export const saveInspectionTask = (
  data: Partial<InspectionTask>
): ThunkResult<Promise<InspectionTask>> => dispatch => {
  if (data.id) {
    return axios
      .put<InspectionTask>(`/inspection-tasks/${data.id}/`, data)
      .then(response => {
        dispatch(loadInspectionTasksSuccess([response.data]));
        return response.data;
      });
  } else {
    return axios.post<InspectionTask>('/inspection-tasks/', data).then(response => {
      dispatch(addInspectionTaskSuccess(response.data));
      return response.data;
    });
  }
};
