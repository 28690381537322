import { createReducerNew } from '@kotify/rehelpers/reducerUtils';
import { Part } from 'vms/features/parts/types';
import {
  InspectionCategory,
  InspectionTask,
  InspectionTaskResult,
  InspectionTaskNote,
} from './types';
import * as _ from 'lodash-es';
import * as Actions from './actions';
import { PART_DELETE_SUCCESS } from 'vms/features/parts/actions';

export interface State {
  categories: { [id: string]: InspectionCategory };
  tasks: { [id: string]: InspectionTask };
  results: { [id: string]: InspectionTaskResult };
}

export const initialState: State = {
  categories: {},
  tasks: {},
  results: {},
};

const loadInspectionCategoriesSuccess = (
  state: State,
  payload: { categories: InspectionCategory[] }
) => {
  state.categories = { ...state.categories, ..._.keyBy(payload.categories, 'id') };
};

const loadInspectionTasksSuccess = (
  state: State,
  payload: { tasks: InspectionTask[] }
) => {
  state.tasks = { ...state.tasks, ..._.keyBy(payload.tasks, 'id') };
};

const addInspectionTaskSuccess = (state: State, payload: { task: InspectionTask }) => {
  const { task } = payload;
  state.tasks = { ...state.tasks, [task.id]: task };
  state.categories[task.category].tasks.push(task.id);
};

const loadInspectionResultsSuccess = (
  state: State,
  payload: { results: InspectionTaskResult[] }
) => {
  state.results = { ...state.results, ..._.keyBy(payload.results, 'id') };
};

const updateInspectionResults = (
  state: State,
  payload: { result: InspectionTaskResult }
) => {
  state.results[payload.result.id] = payload.result;
};

const inspectionTaskNoteCreated = (
  state: State,
  payload: { note: InspectionTaskNote }
) => {
  const result = state.results[payload.note.inspection_result];
  if (result) {
    result.notes.push(payload.note.text);
  }
};

const inspectionResultAddPart = (
  state: State,
  { part, inspectionResultId }: { part: Part; inspectionResultId: string }
) => {
  if (!state.results[inspectionResultId]) {
    return;
  }
  const parts = state.results[inspectionResultId].parts;
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].id === part.id) {
      parts[i] = part;
      return;
    }
  }
  parts.push(part);
};

const inspectionResultDeletePart = (state: State, { part }: { part: Part }) => {
  if (!state.results[part.inspection_result]) {
    return;
  }
  const result = state.results[part.inspection_result];
  result.parts = result.parts.filter(p => p.id !== part.id);
};

export const reducer = createReducerNew<State>(initialState, {
  [Actions.LOAD_INSPECTION_CATEGORIES_SUCCESS]: loadInspectionCategoriesSuccess,
  [Actions.LOAD_INSPECTION_TASKS_SUCCESS]: loadInspectionTasksSuccess,
  [Actions.LOAD_INSPECTION_RESULT_SUCCESS]: loadInspectionResultsSuccess,
  [Actions.UPDATE_INSPECTION_RESULT]: updateInspectionResults,
  [Actions.INSPECTION_TASK_NOTE_CREATED]: inspectionTaskNoteCreated,
  [Actions.INSPECTION_RESULT_ADD_PART]: inspectionResultAddPart,
  [Actions.ADD_INSPECTION_TASK_SUCCESS]: addInspectionTaskSuccess,
  [PART_DELETE_SUCCESS]: inspectionResultDeletePart,
});
