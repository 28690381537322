import { createReducer } from '@kotify/rehelpers/reducerUtils';
import { Vehicle, InWork, ExtendedInWork } from './types';
import { VehicleFlag } from 'vms/features/flags/types';
import * as _ from 'lodash-es';
import {
  LOAD_VEHICLES_SUCCESS,
  VEHICLE_META_LOAD_SUCCESS,
  VEHICLE_APPLY_UPDATE,
  IN_WORK_CHANGE_SUCCESS,
  LOAD_VEHICLE_IN_WORK_SUCCESS,
  VEHICLE_ADD_FLAG,
  VEHICLE_UPDATE_FLAG,
  VEHICLE_IN_STATUS_CREATED,
  VIEW_VEHICLE,
} from './actions';
import RecentVehicles from './RecentVehicles';

export interface State {
  entities: { [id: string]: Vehicle };
  vehicleInWork: InWork | null;
  meta: {
    counts: {
      1: number;
      2: number;
      3: number;
      4: number;
      5: number;
      6: number;
      7: number;
      8: number;
      9: number;
      10: number;
      11: number;
    };
    sources: string[];
    buyers: string[];
  };
}

export const initialState: State = {
  entities: {},
  vehicleInWork: null,
  meta: {
    counts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 },
    sources: [],
    buyers: [],
  },
};

const loadVehiclesSuccess = (state: State, payload: { vehicles: Vehicle[] }) => {
  state.entities = { ...state.entities, ..._.keyBy(payload.vehicles, 'id') };
};

const vehiclesMetaLoadSuccess = (state: State, { meta }: { meta: State['meta'] }) => {
  state.meta = meta;
};

const recalcCounts = (state: State, prev: Vehicle | undefined, next: Vehicle) => {
  if (prev && prev.status !== next.status) {
    state.meta.counts[prev.status] -= 1;
    state.meta.counts[next.status] += 1;
  }
};

const vehicleApplyUpdate = (state: State, { vehicle }: { vehicle: Vehicle }) => {
  const old = state.entities[vehicle.id];
  state.entities = { ...state.entities, [vehicle.id]: vehicle };
  recalcCounts(state, old, vehicle);
};

const inWorkChangeSuccess = (
  state: State,
  {
    inWork,
    finished,
    isCurrentUser,
  }: { inWork: ExtendedInWork; finished: boolean; isCurrentUser: boolean }
) => {
  state.entities[inWork.vehicle.id] = inWork.vehicle;
  if (isCurrentUser) {
    state.vehicleInWork = finished ? null : { ...inWork, vehicle: inWork.vehicle.id };
  }
};

const loadVehicleInWorkSuccess = (
  state: State,
  { inWork }: { inWork: InWork | null }
) => {
  state.vehicleInWork = inWork;
};

const vehicleAddFlag = (state: State, { flag }: { flag: VehicleFlag }) => {
  state.entities[flag.vehicle].flags.push(flag);
};

const vehicleUpdateFlag = (state: State, { flag }: { flag: VehicleFlag }) => {
  const flags = state.entities[flag.vehicle].flags;
  for (let i = 0; i < flags.length; i++) {
    if (flags[i].id === flag.id) {
      flags[i] = flag;
    }
  }
};

const viewVehicle = (_state: State, { vehicle }: { vehicle: Vehicle }) => {
  RecentVehicles.saveToHistory(vehicle);
};

const vehicleInStatusCreated = (
  state: State,
  { id, vehicleId }: { id: string; vehicleId: string }
) => {
  const vehicle = state.entities[vehicleId];
  const existing = vehicle.vehicle_in_statuses.filter(x => x.id === id);
  if (!existing.length) {
    vehicle.vehicle_in_statuses.push({ id, vehicle_status: vehicle.status });
  }
};

export const reducer = createReducer<State>(initialState, {
  [LOAD_VEHICLES_SUCCESS]: loadVehiclesSuccess,
  [VEHICLE_META_LOAD_SUCCESS]: vehiclesMetaLoadSuccess,
  [VEHICLE_APPLY_UPDATE]: vehicleApplyUpdate,
  [IN_WORK_CHANGE_SUCCESS]: inWorkChangeSuccess,
  [LOAD_VEHICLE_IN_WORK_SUCCESS]: loadVehicleInWorkSuccess,
  [VEHICLE_ADD_FLAG]: vehicleAddFlag,
  [VEHICLE_UPDATE_FLAG]: vehicleUpdateFlag,
  [VIEW_VEHICLE]: viewVehicle,
  [VEHICLE_IN_STATUS_CREATED]: vehicleInStatusCreated,
});
