import * as React from 'react';
import { connect } from 'react-redux';
import { loadPartVendors } from './actions';
import VendorSelect, { Props } from 'vms/common/components/VendorSelect';
import { selectPartVendorsList } from 'vms/features/parts/selectors';
import { RootState } from 'vms/app/rootReducer';

const PartVendorSelect = (props: Props) => <VendorSelect {...props} />;

const mapState = (state: RootState) => ({
  vendors: selectPartVendorsList(state),
});

export default connect<
  Pick<Props, 'vendors'>,
  Pick<Props, 'loadVendors'>,
  Omit<Props, 'vendors' | 'loadVendors'>
>(
  mapState,
  { loadVendors: loadPartVendors }
)(PartVendorSelect);
