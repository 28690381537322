import * as _ from 'lodash-es';
import { createReducer } from '@kotify/rehelpers/reducerUtils';
import { User } from './types';
import {
  LOGIN_SUCCESS,
  UNAUTHORIZED_RESPONSE,
  SAVE_LAST_LOCATION,
  LOAD_USERS_SUCCESS,
} from './actions';

export interface State {
  isAuthenticated: boolean;
  currentUserId: string | null;
  lastLocation: string | undefined;
  users: { [key: string]: User };
}

export const initialState: State = {
  isAuthenticated: false,
  currentUserId: null,
  lastLocation: undefined,
  users: {},
};

const loginSuccess = (state: State, { user }: { user: User }) => {
  state.isAuthenticated = true;
  state.currentUserId = user.id;
  state.lastLocation = undefined;
  state.users = { ...state.users, [user.id]: user };
};

const unauthorizedResponse = (state: State) => {
  state.isAuthenticated = false;
  state.currentUserId = null;
};

const saveLastLocation = (state: State, { pathname }: { pathname: string }) => {
  state.lastLocation = pathname;
};

const loadUsersSuccess = (state: State, { users }: { users: User[] }) => {
  state.users = { ...state.users, ..._.keyBy(users, 'id') };
};

export const reducer = createReducer<State>(initialState, {
  [LOGIN_SUCCESS]: loginSuccess,
  [UNAUTHORIZED_RESPONSE]: unauthorizedResponse,
  [SAVE_LAST_LOCATION]: saveLastLocation,
  [LOAD_USERS_SUCCESS]: loadUsersSuccess,
});
