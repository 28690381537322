import { all, fork } from 'redux-saga/effects';
import dialogSagas from 'vms/features/dialog/sagas';
import partsSagas from 'vms/features/parts/sagas';
import gpsSagas from 'vms/features/gps/sagas';
import subletSagas from 'vms/features/sublet/sagas';
import vehiclesSagas from 'vms/features/vehicles/sagas';

export default function* root() {
  yield all([
    fork(dialogSagas),
    fork(partsSagas),
    fork(gpsSagas),
    fork(subletSagas),
    fork(vehiclesSagas),
  ]);
}
