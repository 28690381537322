import { RootState as State } from 'vms/app/rootReducer';
import * as _ from 'lodash-es';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { InspectionTaskResult, InspectionCategory } from 'vms/features/inspection/types';

export const selectInspectionCategories = (state: State) => state.inspection.categories;
export const selectInspectionCategoriesList = createSelector(
  selectInspectionCategories,
  categories => _.sortBy(_.values(categories), 'position')
);
export const selectInspectionTasks = (state: State) => state.inspection.tasks;
const selectInspectionCategory = (state: State, categoryId: string) =>
  state.inspection.categories[categoryId];
export const selectCachedInspectionTasksListByCategory = createCachedSelector(
  selectInspectionCategory,
  selectInspectionTasks,
  (category, tasks) => {
    const categoryTasks = category.tasks
      .map(taskId => tasks[taskId])
      .filter(task => task);
    return _.sortBy(categoryTasks, 'position');
  }
)((_state, categoryId) => categoryId);

export const selectInspectionTasksList = createSelector(
  selectInspectionTasks,
  tasks => _.sortBy(_.values(tasks), 'position')
);

export const selectInspectionResults = (state: State) => state.inspection.results;

export const selectInspectionResultsListByVehicle = createSelector(
  selectInspectionResults,
  results => {
    return _.groupBy(results, 'vehicle');
  }
);

export const selectAvailableActiveInspectionResultList = (
  state: State,
  vehicleId: string
) => {
  const vehicle = state.vehicles.entities[vehicleId];
  return _.values(state.inspection.results).filter(res => {
    const task = state.inspection.tasks[res.task];
    if (!task) {
      return [];
    }
    return (
      res.vehicle === vehicleId &&
      task.active &&
      task.available_in_statuses.indexOf(vehicle.status) !== -1
    );
  });
};

export const selectAvailableActiveInspectionTasksList = (
  state: State,
  vehicleId: string
) => {
  const vehicle = state.vehicles.entities[vehicleId];
  return _.values(state.inspection.tasks).filter(
    task => task.active && task.available_in_statuses.indexOf(vehicle.status) !== -1
  );
};

const selectInspectionCategoryWithVehicle = (
  state: State,
  _vehicleId: string,
  categoryId: string
): InspectionCategory | undefined => state.inspection.categories[categoryId];

export const selectCachedAvailableActiveInspectionTasksListByCategory = createCachedSelector(
  selectInspectionCategoryWithVehicle,
  selectAvailableActiveInspectionTasksList,
  (category, tasksList) => {
    if (category === undefined) {
      return [];
    }
    const tasks = _.keyBy(tasksList, 'id');
    const categoryTasks = category.tasks
      .map(taskId => tasks[taskId])
      .filter(task => task);
    return _.sortBy(categoryTasks, 'position');
  }
)((_state, vehicleId, categoryId) => `${vehicleId}-${categoryId}`);

export const selectCachedActiveAvailableInspectionResultsByTask = createCachedSelector(
  selectAvailableActiveInspectionResultList,
  results => {
    return _.keyBy(results, 'task');
  }
)((_state, vehicleId) => vehicleId);

const selectInspectionResult = (
  state: State,
  resultId: string
): InspectionTaskResult | undefined => state.inspection.results[resultId];

export const selectCachedIsInspectionHighlighted = createCachedSelector(
  selectInspectionResult,
  (result): boolean => {
    return Boolean(
      result &&
        (result.inspection_status === 'ATTN' ||
          result.technician_status === 'ATTN' ||
          result.qc_status === 'ATTN' ||
          result.parts.length)
    );
  }
)((_state, resultId) => resultId);
