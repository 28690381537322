import * as React from 'react';
import {
  Intent,
  Button,
  Classes,
  CollapsibleList,
  IMenuItemProps,
  MenuItem,
} from '@blueprintjs/core';
import { DialogProps } from 'vms/features/dialog/types';
import {
  PartAddDialogEditableData,
  PartAddDialogReadOnlyData,
  Part,
  PartDefinition,
} from './types';
import PartAddDialogBody from './PartAddDialogBody';
import * as styles from './PartAdd.module.scss';
import { connect } from 'react-redux';
import { RootState } from 'vms/app/rootReducer';
import { selectPartDefinitionsByTask } from 'vms/features/parts/selectors';

const renderBreadcrumb = (props: IMenuItemProps) => (
  <span
    className={`${Classes.BREADCRUMB} ${props.active ? Classes.BREADCRUMB_CURRENT : ''} ${
      styles.addPartBreadcrumb
    }`}
  >
    {props.text}
  </span>
);

interface StateProps {
  definitions: { [id: string]: PartDefinition };
}

type OwnProps = DialogProps<
  PartAddDialogEditableData,
  PartAddDialogReadOnlyData,
  ValidationErrors<Part>
>;
type Props = OwnProps & StateProps;

export const renderTitle = (props: Props) => {
  const text = props.readOnlyData.id ? 'Update' : 'Add';
  const { breadcrumbs } = props.readOnlyData;
  return (
    <CollapsibleList
      visibleItemCount={breadcrumbs.length + 1}
      className={`${Classes.BREADCRUMBS} ${styles.addPartBreadcrumbs}`}
      dropdownTarget={<span />}
      visibleItemRenderer={renderBreadcrumb}
    >
      {[...breadcrumbs, text + ' Part'].map((b, i) => (
        <MenuItem key={i} text={b} active={b === text + ' Part'} />
      ))}
    </CollapsibleList>
  );
};

class PartAddDialog extends React.Component<Props> {
  handleChange = (update: Partial<PartAddDialogEditableData>) => {
    this.props.handleChange(update);
    this.props.setErrors({});
  };

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const file = files && files.length ? files[0] : undefined;
    this.props.handleChange({ image: file });
  };

  render() {
    const readOnlyData = this.props.readOnlyData;
    const text = readOnlyData.id ? 'Update' : 'Add';
    return (
      <>
        <div className={Classes.DIALOG_BODY}>
          <PartAddDialogBody
            readOnlyData={readOnlyData}
            editableData={this.props.editableData}
            image={(readOnlyData.id && readOnlyData.image) || null}
            definitions={this.props.definitions}
            errors={this.props.errors}
            onChange={this.handleChange}
            onFileChange={this.onFileChange}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Cancel"
              onClick={this.props.onCancel}
              disabled={this.props.isInProgress}
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={this.props.onOk}
              text={text}
              loading={this.props.isInProgress}
              disabled={
                this.props.readOnlyData.isQuickRequest && !this.props.editableData.task
              }
            />
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: RootState, ownProps: OwnProps): StateProps => {
  const { task } = ownProps.editableData;
  const definitions = task ? selectPartDefinitionsByTask(state)[task] : {};
  return {
    definitions,
  };
};

export default connect<StateProps, null, OwnProps>(mapState)(PartAddDialog);
