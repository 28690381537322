import { createReducerNext } from '@kotify/rehelpers/reducerUtils';
import * as _ from 'lodash-es';
import { Flag, VehicleFlag } from './types';
import {
  Actions,
  LOAD_FLAGS_SUCCESS,
  FLAG_ADD,
  FLAG_REMOVE,
  FLAG_CLOSE_DIALOGS,
} from './actions';
import { Vehicle } from 'vms/features/vehicles/types';

export interface State {
  entities: { [key: string]: Flag };
  dialogAddData: {
    vehicle: Vehicle;
    notes: string;
    assigned_to: string[];
    flag: Flag;
  } | null;
  dialogRemoveData: { vehicle: Vehicle; vehicleFlag: VehicleFlag } | null;
}

export const initialState: State = {
  entities: {},
  dialogAddData: null,
  dialogRemoveData: null,
};

export const reducer = createReducerNext<State, Actions>(
  initialState,
  (state, action) => {
    switch (action.type) {
      case LOAD_FLAGS_SUCCESS:
        state.entities = { ...state.entities, ..._.keyBy(action.payload.flags, 'id') };
        return;
      case FLAG_ADD:
        state.dialogAddData = { ...action.payload, notes: '', assigned_to: [] };
        return;
      case FLAG_REMOVE:
        state.dialogRemoveData = action.payload;
        return;
      case FLAG_CLOSE_DIALOGS:
        state.dialogRemoveData = null;
        state.dialogAddData = null;
        break;

      default:
    }
  }
);
