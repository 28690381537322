import axios, { AxiosPromise } from 'axios';
import { Vehicle, InWork, ExtendedInWork } from './types';
import { State } from './reducer';
import { VehicleFlag } from 'vms/features/flags/types';
import { ThunkResult } from 'vms/app/rootReducer';
import { Dispatch } from 'redux';

export const LOAD_VEHICLES_SUCCESS = 'LOAD_VEHICLES_SUCCESS';
export const VEHICLE_META_LOAD_SUCCESS = 'VEHICLE_META_LOAD_SUCCESS';
export const VEHICLE_APPLY_UPDATE = 'VEHICLE_APPLY_UPDATE';
export const IN_WORK_CHANGE_SUCCESS = 'IN_WORK_CHANGE_SUCCESS';
export const LOAD_VEHICLE_IN_WORK_SUCCESS = 'LOAD_VEHICLE_IN_WORK_SUCCESS';
export const VEHICLE_ADD_FLAG = 'VEHICLE_ADD_FLAG';
export const VEHICLE_UPDATE_FLAG = 'VEHICLE_UPDATE_FLAG';
export const VIEW_VEHICLE = 'VIEW_VEHICLE';
export const LOAD_VEHICLE = 'LOAD_VEHICLE';
export const VEHICLE_IN_STATUS_CREATED = 'VEHICLE_IN_STATUS_CREATED';

export const vehiclesMetaLoadSuccess = (meta: State['meta']) => ({
  type: VEHICLE_META_LOAD_SUCCESS,
  meta,
});

export const vehicleApplyUpdate = (vehicle: Vehicle) => ({
  type: VEHICLE_APPLY_UPDATE,
  vehicle,
});

export const loadVehiclesSuccess = (vehicles: Vehicle[]) => ({
  type: LOAD_VEHICLES_SUCCESS,
  vehicles,
});

export const loadVehicleInWorkSuccess = (inWork: InWork) => ({
  type: LOAD_VEHICLE_IN_WORK_SUCCESS,
  inWork,
});

export const inWorkChangeSuccess = (
  inWork: ExtendedInWork,
  finished: boolean,
  isCurrentUser: boolean
) => ({
  type: IN_WORK_CHANGE_SUCCESS,
  inWork,
  finished,
  isCurrentUser,
});

export const vehicleAddFlag = (flag: VehicleFlag) => ({
  type: VEHICLE_ADD_FLAG,
  flag,
});

export const vehicleUpdateFlag = (flag: VehicleFlag) => ({
  type: VEHICLE_UPDATE_FLAG,
  flag,
});

export interface InWrokChagneArgsExtraData {
  request_parts?: boolean;
  user?: string;
}
export interface InWorkChangeArgs {
  vehicleId: string;
  toState: boolean;
  extraData: InWrokChagneArgsExtraData;
}
export const inWorkChange = (
  args: InWorkChangeArgs
): ThunkResult<AxiosPromise<ExtendedInWork>> => (dispatch, getState) => {
  return axios
    .post<ExtendedInWork>(`/vehicles/${args.vehicleId}/in_work/`, {
      ...args.extraData,
      in_work: args.toState,
    })
    .then(response => {
      const currentUserId = getState().accounts.currentUserId;
      const isCurrentUser =
        args.extraData.user === undefined || args.extraData.user === currentUserId;
      dispatch(inWorkChangeSuccess(response.data, !args.toState, isCurrentUser));
      return response;
    });
};

export const viewVehicle = (vehicle: Vehicle) => ({ type: VIEW_VEHICLE, vehicle });

export const loadVehicle = (vehicleId: string) => (dispatch: Dispatch<any>) => {
  return new Promise<Vehicle>((resolve, reject) => {
    dispatch({
      type: LOAD_VEHICLE,
      payload: { resolve, reject, vehicleId },
    });
  });
};

export const vehicleInStatusCreated = (id: string, vehicleId: string) => ({
  type: VEHICLE_IN_STATUS_CREATED,
  id,
  vehicleId,
});
