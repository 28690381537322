import axios from 'axios';
import { Dispatch } from 'redux';
import * as _ from 'lodash-es';

import { NotificationSettingsEntry } from './types';
import { RootState } from 'vms/app/rootReducer';

export const NOTIFICATION_SETTINGS_UPDATED = 'NOTIFICATION_SETTINGS_UPDATED';
export const LOAD_NOTIFICATION_SETTINGS = 'LOAD_NOTIFICATION_SETTINGS';

export const updateNotificationSettings = (
  notificationSettingsEntry: NotificationSettingsEntry
) => ({
  type: NOTIFICATION_SETTINGS_UPDATED,
  notificationSettingsEntry,
});

export const loadNotificationSettings = (
  notificationSettings: NotificationSettingsEntry[]
) => ({
  type: LOAD_NOTIFICATION_SETTINGS,
  notificationSettings,
});

export const getNotificationSettings = () => (
  dispatch: Dispatch<any>,
  getState: () => RootState
): Promise<any> => {
  const { notificationSettings } = getState().organization;
  if (!_.isEmpty(notificationSettings)) {
    return Promise.resolve();
  }
  return axios
    .get<PaginatedResults<NotificationSettingsEntry>>('/notification-settings/')
    .then(response => {
      dispatch(loadNotificationSettings(response.data.results));
    });
};
