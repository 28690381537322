import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'vms/app/rootReducer';
import { Dialog, Spinner } from '@blueprintjs/core';
import { Actions } from './actions';
import dialogsConfig from './dialogsConfig';
import { DialogState } from './types';

interface DispatchProps {
  cancelDialog: typeof Actions.cancelDialog;
  finishDialog: typeof Actions.finishDialog;
  setErrors: typeof Actions.setErrors;
  changeDialogData: typeof Actions.changeDialogData;
}

type Props = DialogState & DispatchProps;

export class AppDialog extends React.Component<Props> {
  render() {
    let content = null;
    let config;
    let title: React.ReactNode = 'Loading';
    if (this.props.code) {
      if (!this.props.readyToRender) {
        content = <Spinner />;
      } else {
        config = dialogsConfig[this.props.code];
        const Component = config.component;
        if (config.title instanceof Function) {
          title = config.title(this.props as any);
        } else {
          title = config.title;
        }
        content = (
          <Component
            onCancel={this.props.cancelDialog}
            onOk={this.props.finishDialog}
            editableData={this.props.editableData!}
            readOnlyData={this.props.readOnlyData!}
            isInProgress={this.props.isInProgress}
            errors={this.props.errors}
            handleChange={this.props.changeDialogData}
            setErrors={this.props.setErrors}
          />
        );
      }
    }
    return (
      <Dialog
        icon={config ? config.icon : undefined}
        isOpen={Boolean(this.props.code)}
        onClose={this.props.cancelDialog}
        title={title}
        canOutsideClickClose={false}
      >
        {content}
      </Dialog>
    );
  }
}

const mapState = (state: RootState): typeof state.dialog => {
  return { ...state.dialog };
};

export default connect<DialogState, DispatchProps>(
  mapState,
  {
    cancelDialog: Actions.cancelDialog,
    finishDialog: Actions.finishDialog,
    changeDialogData: Actions.changeDialogData,
    setErrors: Actions.setErrors,
  }
)(AppDialog);
