export enum NoteModel {
  VendorSublet = 'VENDOR_SUBLET',
  EntireInspection = 'STATE_INSPECTION',
  Vehicle = 'VEHICLE',
  VehicleInStatus = 'VEHICLE_IN_STATUS',
}

export interface Note {
  id: string;
  model: NoteModel;
  object_id: string;
  text: string;
  created_at: string;
  created_by: string;
}

export interface NoteQueryParams {
  object_id: string | string[];
  model: NoteModel;
}

export interface NoteCreatorData {
  params: NoteQueryParams;
  afterNoteAdd?: () => any;
}
