import * as _ from 'lodash-es';
import { createSelector } from 'reselect';

import { RootState as State } from 'vms/app/rootReducer';
import { NoteModel, Note } from './types';

export const selectNotes = (state: State) => state.notes.entities;
export const selectNoteCreatorData = (state: State) => state.notes.noteCreatorData;

export const selectNotesByModel = createSelector(
  selectNotes,
  notes => {
    const byModel = _.mapValues(_.groupBy(_.values(notes), 'model'), v =>
      _.groupBy(v, 'object_id')
    );
    return byModel as {
      [key in NoteModel]: { [objectId: string]: Note[] | undefined } | undefined
    };
  }
);
