import { INITIAL_INSPECTION_STATUSES } from './constants';
import { Status, Vehicle } from './types';

export const isInitialInspection = (status: Status) => {
  return INITIAL_INSPECTION_STATUSES.indexOf(status) !== -1;
};

export const getVehicleInStatusId = (vehicle: Vehicle) => {
  if (!vehicle.vehicle_in_statuses) {
    return null;
  }
  const vehicleInStatus = vehicle.vehicle_in_statuses.filter(
    x => x.vehicle_status === vehicle.status
  )[0];
  if (vehicleInStatus) {
    return vehicleInStatus.id;
  }
  return null;
};
