/**
 * The main reason for AppDialog existance is to provide a way to handle
 * several requests for "Open Dialog X" from application.
 * The main goal is to handle these requests and show dialogs one after
 * another without any logic in app or in dialogs for supporting these "steps".
 *
 * To create a new dialog you must create following entities:
 *  - dialog body component,
 *  - function "load dialog data"
 *  - function "save dialog data/finish dialog",
 *  - dialog title (string or function)
 *  - icon name
 *
 * The worflow is the following:
 *  - app dialog listen for REQUEST_START_DIALOG
 *  - on new action it open AppDialog with loader
 *  - load data using your load function
 *  - store that data in dialog store
 *  - AppDialog renders inner Dialog and provide the data from the store
 *  - on cancel it closes dialog and cancel all other dialog requests that were already fired
 *  - on finish it calls finish function that you provided
 *  - on success it closes dialog
 *  - pull next dialog from queue
 *
 * To open dialog dispatch action with type REQUEST_START_DIALOG,
 * payload must contains dialogCode from DIALOGS_CONFIG. Payload will be passed
 * to your load dialog function.
 */

import { DialogConfig } from './types';
import PartAddDialog, { renderTitle } from 'vms/features/parts/PartAddDialog';
import {
  loadPartAddDialogData,
  savePartAddDialogData,
  loadPartEditDialogData,
} from 'vms/features/parts/sagas';
import PartEditDialog from 'vms/features/parts/PartEditDialog';

const DIALOGS_CONFIG: DialogConfig = {
  PART_ADD_DIALOG: {
    component: PartAddDialog,
    loadData: loadPartAddDialogData,
    saveData: savePartAddDialogData,
    title: renderTitle,
    icon: 'barcode',
  },
  PART_EDIT_DIALOG: {
    component: PartEditDialog,
    loadData: loadPartEditDialogData,
    saveData: savePartAddDialogData,
    title: 'Edit Part',
    icon: 'barcode',
  },
};

export default DIALOGS_CONFIG;
